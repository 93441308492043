import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const CardExpanded = ({ image, heading, copy, link, centered }) => {
  const { romero } = brand;
  if (link?.link?.length > 3) {
    return (
      <AppLink
        to={link.link}
        className="group flex h-full w-full flex-col bg-white shadow-lg transition duration-200 hover:shadow-xl"
      >
        <div className="w-full overflow-hidden">
          <div className="transform transition duration-200 group-hover:scale-105">
            <Image image={image} aspectRatio={[8, 5]} />
          </div>
        </div>
        <div
          className={`flex flex-grow flex-col p-3 lg:p-4
        ${
          centered
            ? "items-center justify-center text-center"
            : "items-start justify-start text-left"
        } `}
        >
          <Text
            variant={romero ? "h6" : "body--large-tight"}
            className={clsx({
              "font-bold text-red": !romero,
              "text-blue": romero,
            })}
          >
            {heading}
          </Text>
          <Text
            variant={romero ? "body--base" : "body"}
            className={clsx({
              "my-2": !romero,
              "mb-4 mt-2": romero,
            })}
          >
            {copy}
          </Text>
          {link?.text && (
            <div className="mt-auto">
              <ButtonAlt size="sm" noIcon={!romero}>
                {link.text}
              </ButtonAlt>
            </div>
          )}
        </div>
      </AppLink>
    );
  }
  return (
    <div className="flex h-full w-full flex-col bg-white shadow-lg transition duration-200">
      <div className="w-full overflow-hidden">
        <div className="transform transition duration-200 group-hover:scale-105">
          <Image image={image} aspectRatio={[8, 5]} />
        </div>
      </div>
      <div
        className={`flex flex-grow flex-col p-3 lg:p-4
        ${
          centered
            ? "items-center justify-center text-center"
            : "items-start justify-start text-left"
        } `}
      >
        <Text
          variant={romero ? "h6" : "body--large-tight"}
          className={clsx({
            "font-bold text-red": !romero,
            "text-blue": romero,
          })}
        >
          {heading}
        </Text>
        <Text variant={romero ? "body" : "body"} className="mt-2">
          {copy}
        </Text>
        {link?.text && (
          <div className="mt-2">
            <ButtonAlt
              size="sm"
              noIcon={!romero}
              color={romero ? "gold" : "red"}
            >
              {link.text}
            </ButtonAlt>
          </div>
        )}
      </div>
    </div>
  );
};

CardExpanded.defaultProps = {
  centered: false,
};

export default CardExpanded;
