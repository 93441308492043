import resolveAction from "@dataResolvers/resolveAction";

const resolveSidebar = ({
  hideSidebar,
  hideDefaultActions,
  sidebarActions,
  hideDefaultLinks,
  sidebarLinks,
}) => {
  if (
    (!hideSidebar && (sidebarActions.length || sidebarLinks.length)) ||
    !hideDefaultActions ||
    !hideDefaultLinks
  )
    return {
      hideDefaultActions,
      hideDefaultLinks,
      actions: sidebarActions?.map(a => resolveAction(a)) || [],
      links:
        sidebarLinks
          ?.filter(l => l?.listLink?.url || l?.action)
          ?.map(l => {
            return {
              uid: l.uid,
              text: l.linkText,
              url: l.listLink?.url || l.action?.[0]?.url,
            };
          }) || [],
    };
  return null;
};

export default resolveSidebar;
