import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbLargeCopy = ({ copy, type }) => {
  return (
    <CbWrapper type={type}>
      <Text variant="body--2xl-tight">{copy}</Text>
    </CbWrapper>
  );
};

CbLargeCopy.defaultProps = {};

export default CbLargeCopy;
