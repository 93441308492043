module.exports = {
  colors: {
    black: { alpha: "#121212CC", dark: "#010101", DEFAULT: "#121212" },
    white: { DEFAULT: "#FFFFFF" },
    "near-white": { DEFAULT: "#FCFBF8" }, // white nav bg
    cream: { DEFAULT: "#F7F6F3", dark: "#F0EDE8" }, // primary bg color
    gold: { DEFAULT: "#DDA71B" }, // keylines
    blue: {
      light: "#DFEAFE",
      dark: "#033156",
      darker: "#01192b",
      DEFAULT: "#0050BF",
    },
    gray: {
      DEFAULT: "#3D5B64",
      light: "#3D5B64CC", // light gray text color, 80% opacity
      "light-alpha": "#3D5B644D", // 30% opacity
    },
  },
  lineHeight: {
    less: ".9",
    tighter: 1.125,
  },
  fontFamily: {
    sans: ["Neue Haas Unica", "sans-serif"],
    serif: ["Austin News Headline", "serif"],
  },
};
