import { useStaticQuery, graphql } from "gatsby";

// eslint-disable-next-line no-unused-vars

import resolveDefaults from "@dataResolvers/resolveDefaults";

const query = graphql`
  query LgcaAcDefaultsQuery {
    craft {
      globalSet(handle: "defaults", site: "lgcaAc") {
        ... on Craft_defaults_GlobalSet {
          ...DefaultsFragment
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => resolveDefaults(craft);

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
