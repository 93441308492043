import resolveImage from "@dataResolvers/resolveImage";
import resolveSidebar from "./resolveSidebar";

const resolveDefaults = ({ globalSet }) => {
  const {
    heading0,
    url,
    copy0,
    heading1,
    copy1,
    heading2,
    metaTitle,
    metaImage,
    actionDefaultCtaText,
    sidebarActions,
    sidebarLinks,
  } = globalSet;

  return {
    url,
    action: {
      thankYouHeading: heading0,
      thankYouCopy: copy0,
      completedHeading: heading1,
      completedCopy: copy1,
      shareCta: heading2,
      defaultActionCtas: actionDefaultCtaText[0],
    },
    meta: {
      image: resolveImage(metaImage),
      siteTitle: metaTitle,
    },
    sidebar: resolveSidebar({ sidebarActions, sidebarLinks }),
  };
};

export default resolveDefaults;
