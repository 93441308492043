import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Text, Button, ButtonWhite, Icon } from "@atoms";
import PaintStrokeIcon from "@svg/paintStroke.svg";

const LevelCardLarge = ({ icon, heading, subheading, copy, link }) => {
  const { lakota, romero } = brand;

  return (
    <div
      className={clsx("relative mx-auto max-w-xl", {
        "text-white": !romero,
      })}
    >
      {lakota && (
        <div className="absolute inset-0">
          <PaintStrokeIcon className="absolute inset-0 h-full w-full fill-red" />
        </div>
      )}
      <div
        className={clsx(
          "relative z-10 flex flex-col items-center justify-center p-4 text-center lg:p-8",
          {
            "bg-blue text-white shadow-xl": romero,
          }
        )}
      >
        <div>
          <Icon
            name={`${icon}Membership`}
            className={clsx("h-12 w-12", {
              "text-white": true,
            })}
            fitHeight
          />
        </div>
        <div className="my-1">
          <Text variant="h5">{heading}</Text>
        </div>
        <div className="my-1">
          <Text className="rich-text font-sans font-bold">{subheading}</Text>
        </div>
        <div className="my-3">
          <Text variant="body--xl-tight" className="rich-text max-w-lg">
            {copy}
          </Text>
        </div>
        {link && link.link && (
          <div className="mt-3">
            {lakota && <ButtonWhite to={link.link}>{link.text}</ButtonWhite>}
            {romero && (
              <Button to={link.link} rounded outline color="gold">
                {link.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

LevelCardLarge.defaultProps = {};

export default LevelCardLarge;
