import { useState, useEffect } from "react";

const useDevice = () => {
  const [isIOS, setISIOS] = useState(undefined);
  useEffect(() => {
    setISIOS(
      ([
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
        // eslint-disable-next-line eqeqeq
        navigator.userAgent.search("Firefox") === -1 &&
        navigator.userAgent.search("FxiOS") === -1
    );
  }, []);
  return isIOS;
};

export default useDevice;
