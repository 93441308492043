import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query LakotaHeaderQuery {
    craft {
      globalSet(handle: "header", site: "lakotaGc") {
        ... on Craft_header_GlobalSet {
          name
          navHeading
          navCopy
          navCampaigns {
            ... on Craft_navCampaigns_campaign_BlockType {
              uid
              enabled
              campaign(site: "*") {
                url
                slug
                title
                ... on Craft_issue_issue_Entry {
                  heading0
                }
              }
            }
          }
          linkList {
            ... on Craft_linkList_action_BlockType {
              uid
              enabled
              action {
                url
              }
              linkText
            }
            ... on Craft_linkList_listLink_BlockType {
              uid
              enabled
              linkText
              listLink {
                url
              }
            }
          }
          secondaryNav {
            ... on Craft_secondaryNav_navLink_BlockType {
              uid
              enabled
              navLink {
                url
              }
              linkText
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { navHeading, navCopy, navCampaigns, secondaryNav, linkList } =
    craft.globalSet;
  return {
    heading: navHeading,
    copy: navCopy,
    campaigns: navCampaigns
      .filter(c => c.campaign[0])
      .map(c => {
        return {
          uid: c.uid,
          url: c.campaign[0].url,
          heading: c.campaign[0]?.heading0 || c.campaign[0].title,
        };
      }),
    primaryNav: linkList.map(item => {
      return {
        uid: item.uid,
        link:
          item.action && item.action.length
            ? item.action[0].url
            : item.listLink?.[0]?.url,
        text: item.linkText,
      };
    }),
    secondaryNav: secondaryNav.map(link => {
      return {
        uid: link.uid,
        link: link.navLink?.[0]?.url,
        text: link.linkText,
      };
    }),
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  return dataResolver({ craft });
};

export default useData;
