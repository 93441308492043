import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import Text from "./Text";

const CbWrapper = ({ indent: _indent, count, children, margin, type }) => {
  const { romero } = brand;
  let indent = _indent;
  if (count) {
    indent = true;
  }
  const marginValues = {
    removeTop: "mb-10 -mt-6",
    topOnly: "mt-10",
    bottomOnly: "mb-10",
    default: "my-10",
  };
  return (
    <div
      className={`content-builder__${type} relative overflow-hidden ${
        indent ? "pl-6" : ""
      } ${marginValues[margin]}`}
    >
      {count && (
        <div
          className={clsx("absolute left-0 top-0 w-4", {
            "text-black": romero,
            "font-bold text-red": !romero,
          })}
        >
          <Text variant={romero ? "list" : "body--large-tight"}>
            {`${count}.`}{" "}
          </Text>
        </div>
      )}
      {children}
    </div>
  );
};

CbWrapper.defaultProps = {
  margin: "default",
  indent: false,
};

export default CbWrapper;
