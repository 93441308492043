const actionReducer = (state, action) => {
  const { type, level, amount, total, status, frequency, trackingId } = action;
  let { visibleAmounts } = state;
  let newLevel = state.level;
  let thresholds = [];
  switch (type) {
    case "setTrackingId":
      return { ...state, trackingId };
    case "setFrequency":
      if (state.levels && frequency !== "once") {
        visibleAmounts =
          state.levels[typeof state.level !== "undefined" ? state.level : 1][
            typeof frequency !== "undefined" ? frequency : "monthly"
          ];
      }
      return { ...state, frequency, visibleAmounts };
    case "setStatus":
      return { ...state, status };
    case "setAmount":
      // eslint-disable-next-line no-case-declarations
      if (state.levels && state.frequency !== "once") {
        thresholds = state.levels.map(l => [
          l[frequency || state.frequency][0],
          [...l[frequency || state.frequency]].pop(),
        ]);
      }
      thresholds.forEach(([min, max], i) => {
        if (
          (amount <= max || i === thresholds.length - 1) &&
          (amount >= min || i === 0)
        ) {
          newLevel = i;
        }
      });

      if (state.levels && state.frequency !== "once") {
        visibleAmounts =
          state.levels[newLevel][frequency || state.frequency || "monthly"];
      }

      // eslint-disable-next-line no-case-declarations
      const newIdx = visibleAmounts?.findIndex(a => {
        return a === amount;
      });

      return {
        ...state,
        amount,
        total,
        visibleAmounts,
        level: newLevel,
        amountIdx: newIdx !== -1 ? newIdx : state.amountIdx,
      };
    case "setLevel":
      if (state.levels && state.frequency !== "once") {
        visibleAmounts =
          state.levels[typeof level !== "undefined" ? level : 1][
            typeof state.frequency !== "undefined" ? state.frequency : "monthly"
          ];
      }
      return {
        ...state,
        level,
        visibleAmounts,
        // amount: visibleAmounts[state.amountIdx],
      };
    default:
      return state;
  }
};

export default actionReducer;
