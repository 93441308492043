import React from "react";

import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { StatusBanner } from "@molecules";
import { Image, Container } from "@atoms";

const HeroContainer = ({
  children,
  image,
  headerBg,
  headerBgLight,
  gradient,
  light,
  editKey,
  slug,
}) => {
  return (
    <>
      {headerBgLight && <div className="h-24 bg-cream" />}
      {headerBg && <div className="h-24 bg-brown" />}
      {editKey && editKey[slug] && (
        <StatusBanner editKey={editKey[slug]} slug={slug} />
      )}
      <section className="relative bg-brown py-12 md:pb-24">
        <div className="absolute inset-0 z-0">
          {gradient && (
            <div className="absolute inset-0 z-10 bg-gradient-to-t from-gray-light" />
          )}
          <div className="absolute inset-0 z-0">
            {image && (
              <Image
                image={image}
                ixParams={
                  light
                    ? { bri: "30", con: "-10", fit: "max" }
                    : { exp: "-10", con: 5, fit: "max" }
                }
                fill
              />
            )}
          </div>
        </div>
        <div className="relative z-20">
          <Container>
            <div
              className={clsx("flex w-full flex-wrap", {
                "mt-24": !headerBg,
              })}
            >
              {children}
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

HeroContainer.defaultProps = {};

export default HeroContainer;
