import React from "react";

import { AppLink } from "@base";
import { SiteNav } from "@molecules";
import { Icon, Container, Button } from "@atoms";
import { useAppState } from "@state";
import useLakotaAcHeaderData from "@staticQueries/lakotaAc/HeaderQuery";
import clsx from "clsx";

const LakotaAcHeader = () => {
  const data = useLakotaAcHeaderData();
  const { button } = data;

  const [{ layout }, dispatch] = useAppState();
  const { theme, hideNav } = layout;
  const colors = {
    dark: {
      text: "text-white",
    },
    light: {
      text: "text-black",
    },
  };
  return (
    <>
      <SiteNav data={data} />
      <header className="absolute left-0 right-0 top-0 z-40 w-full sm:z-[100]">
        <Container>
          {/* <div className="left-3 -mb-2 flex items-center pt-2 text-xs sm:left-6">
            <AppLink
              to="https://lakotalaw.org"
              className="flex items-center gap-1 font-bold uppercase text-black"
            >
              <Icon name="arrow" className="h-3 w-3 rotate-180" />

              <span>Main Site</span>
            </AppLink>
          </div> */}
          <div className="relative -mx-3 flex h-24 items-center sm:-mx-6">
            <div className="px-3 sm:px-6">
              <AppLink
                className="relative block h-12 w-40 xxs:h-10 xxs:w-32 sm:w-48"
                to="/"
              >
                <Icon
                  name="lakotaAcLogo"
                  className="absolute inset-0 h-auto w-full"
                />
                <Icon
                  name="lakotaAcLogoInverse"
                  className={`absolute inset-0 h-auto w-full transition duration-200
                  ${theme === "light" ? "opacity-0" : "opacity-100"}`}
                />
              </AppLink>
            </div>
            {/* TODO: Get from globals */}
            <div
              className={clsx(
                colors[theme].text,
                "ml-auto flex items-center px-3 text-sm font-bold uppercase transition duration-200 sm:px-6",
                {
                  "pointer-events-none opacity-0": hideNav,
                }
              )}
            >
              {button?.url && (
                <div className="mx-4 hidden sm:block">
                  <Button size="sm" to={button.url}>
                    {button.text}
                  </Button>
                </div>
              )}
              <div className="ml-4">
                <button
                  type="button"
                  className="inline-flex items-center text-xs"
                  onClick={() => {
                    dispatch({ type: "toggleNav" });
                  }}
                >
                  Menu <Icon name="bars" className="ml-2 h-8 w-8 text-red" />
                </button>
              </div>
            </div>
            {/* <div className="px-3 sm:px-6 hidden">mobile nav button</div> */}
          </div>
        </Container>
      </header>
    </>
  );
};

LakotaAcHeader.defaultProps = {};

export default LakotaAcHeader;
