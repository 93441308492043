import React, { useRef, useState, useEffect, useReducer } from "react";
import clsx from "clsx";
import { useAppState } from "@state";
import { Text, AtForm } from "@atoms";
import { AppLink } from "@base";
import useGivingCampaignData from "@staticQueries/lakotaGc/GivingCampaignQuery";
import actionReducer from "@reducers/action";
import _brand from "@utils/brand";

const { romero } = _brand;

const ActionContainer = ({ callbacks, goal, total }) => {
  const formContainer = useRef();
  // the data
  const data = useGivingCampaignData();
  const {
    // url,
    activistCodesExclusive,
    // issue: _issue,
    // showImpactGrid: _showImpactGrid,
    // showSocialFeed: _showSocialFeed,
    // showRelatedContent: _showRelatedContent,
    // impact: _impactGrid,
    // social: _social,
    // relatedContent: _relatedContent,
    // layout,
    levels,
    // meta,
    // slug,
    // wide,
    campaign,
  } = data;

  const { donateFormId, membershipFormId } = campaign;
  const tabs = [
    {
      heading: "Donate",
      form: "donate form",
      formid: donateFormId,
    },
    {
      heading: "Become a Member",
      form: "become a member form",
      formid: membershipFormId,
    },
  ];

  const [{ ea }] = useAppState();

  const hasCodes = ea && ea.activistCodes;

  const getStatus = () => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoSubmitSuppressed = urlParams.get("autoSubmitSuppressed");
      if (autoSubmitSuppressed === "true") {
        return "unsubmitted";
      }
    }
    if (
      hasCodes &&
      activistCodesExclusive &&
      activistCodesExclusive.find(code => ea.activistCodes.includes(code))
    ) {
      return "completed";
    }
    return "unsubmitted";
  };

  const [actionState, actionDispatch] = useReducer(actionReducer, {
    trackingId: null,
    status: getStatus(),
    levels,
    amount: null,
    // todo: get initial level from cms
    level: levels ? 0 : null,
    // todo: get total using transact fee
    total: null,
    // todo: make this editable in the CMS
    // eslint-disable-next-line no-nested-ternary
    frequency: levels?.length ? (romero ? "annual" : "monthly") : "once",
    visibleAmounts: levels
      ? levels?.[1]?.[romero ? "annual" : "monthly"]
      : null,
    amountIdx: 0,
  });

  // tab selection logic
  const [active, setActive] = useState(0);

  useEffect(() => {
    // TODO: force component to refresh
    // formContainer.current;
  }, [active]);

  return (
    <div
      className={clsx("relative mt-6 flex w-full flex-col items-start md:mt-0")}
    >
      {/* tabs */}
      <div className="flex w-full items-stretch">
        {tabs?.map((tab, index) => {
          return (
            <AppLink
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="h-full"
              onClick={() => setActive(index)}
            >
              <div
                className={clsx(
                  "flex h-full items-center justify-center rounded-t-lg p-3",
                  {
                    "bg-white": active === index,
                    "bg-gray-light": active !== index,
                  }
                )}
              >
                <Text variant="body--large" className="font-bold text-red">
                  {tab.heading}
                </Text>
              </div>
            </AppLink>
          );
        })}
      </div>
      <div
        ref={formContainer}
        className="h-full w-full flex-grow bg-white text-black"
      >
        <h3 className="font-sans mt-6 text-center text-sm font-bold uppercase">
          Give to Lakota Law in the spirit of Wopila!
        </h3>
        {goal && (
          <div className="relative z-10 p-4 pb-0 lg:p-6 lg:pb-0">
            <div className="mb-1 flex w-full justify-between text-xs">
              <span>{`$${(total || 0).toLocaleString()} Raised`}</span>
              <span>{`$${goal.toLocaleString()} Goal`}</span>
            </div>
            <div className="border-1 relative h-4 w-full overflow-hidden rounded-full border-gray bg-gray-300">
              <div
                className="absolute left-0 top-0 h-4 w-1/2 bg-red transition transition-all duration-700"
                style={{
                  width: `${((total / goal) * 100).toFixed(2)}%`,
                }}
              />
            </div>
          </div>
        )}
        {active === 0 && (
          <AtForm
            short
            formId={tabs[0].formid}
            callbacks={callbacks}
            actionState={actionState}
            actionDispatch={actionDispatch}
          />
        )}
        {active === 1 && (
          <AtForm
            short
            formId={tabs[1].formid}
            callbacks={callbacks}
            actionState={actionState}
            actionDispatch={actionDispatch}
          />
        )}
      </div>
    </div>
  );
};

ActionContainer.defaultProps = {};

export default ActionContainer;
