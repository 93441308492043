import React from "react";
import { Text, ButtonAlt, Container, Image } from "@atoms";

const QuoteSection = ({ backgroundImage, heading, attribution, link }) => {
  return (
    <div className="relative bg-blue-dark py-12 md:py-24">
      {backgroundImage && (
        <div className="absolute inset-0 z-0 opacity-60">
          <Image
            image={backgroundImage}
            ixParams={{ exp: "-7", con: 5 }}
            fill
            fit="cover"
          />
        </div>
      )}
      <Container className="relative">
        <div className="flex max-w-3xl flex-col pl-6 md:pl-0">
          <Text className="font-serif text-3xl italic leading-tight text-white md:text-4xl">
            {heading}
          </Text>
          <Text variant="body" className="mt-4 text-white">
            {attribution}
          </Text>
          <div className="mt-8">
            <ButtonAlt to={link.link} color="gold" light>
              {link.text}
            </ButtonAlt>
          </div>
        </div>
      </Container>
    </div>
  );
};

QuoteSection.defaultProps = {};

export default QuoteSection;
