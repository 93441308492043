exports.components = {
  "component---src-craft-dynamic-queries-romero-about-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/AboutRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-about-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-action-query-js": () => import("./../../../src/craft/dynamicQueries/romero/ActionQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-action-query-js" */),
  "component---src-craft-dynamic-queries-romero-article-query-js": () => import("./../../../src/craft/dynamicQueries/romero/ArticleQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-article-query-js" */),
  "component---src-craft-dynamic-queries-romero-become-member-query-js": () => import("./../../../src/craft/dynamicQueries/romero/BecomeMemberQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-become-member-query-js" */),
  "component---src-craft-dynamic-queries-romero-blog-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/BlogRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-blog-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-home-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/HomeRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-home-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-membership-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/MembershipRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-membership-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-other-ways-to-give-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/OtherWaysToGiveRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-other-ways-to-give-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-page-query-js": () => import("./../../../src/craft/dynamicQueries/romero/PageQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-page-query-js" */),
  "component---src-craft-dynamic-queries-romero-resources-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/ResourcesRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-resources-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-start-a-fundraiser-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/StartAFundraiserRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-start-a-fundraiser-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-team-romero-query-js": () => import("./../../../src/craft/dynamicQueries/romero/TeamRomeroQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-team-romero-query-js" */),
  "component---src-craft-dynamic-queries-romero-video-query-js": () => import("./../../../src/craft/dynamicQueries/romero/VideoQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-video-query-js" */),
  "component---src-craft-dynamic-queries-romero-writing-query-js": () => import("./../../../src/craft/dynamicQueries/romero/WritingQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-romero-writing-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

