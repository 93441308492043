import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query GdprQuery {
    craft {
      globalSet(handle: "gdpr") {
        ... on Craft_gdpr_GlobalSet {
          # boolean0
          # heading0
          # copy0
          # heading1
          uid
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { boolean0, heading0, copy0, heading1 } = globalSet;
  return {
    enabled: boolean0,
    heading: heading0,
    copy: copy0,
    button: heading1,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
