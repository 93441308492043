import React, { useRef, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";

const useDynamicSVGImport = (name, options = {}) => {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { onCompleted, onError, encode } = options;

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const importIcon = async () => {
      let Icon;
      try {
        Icon = (await import(`../svg/${name}.svg`)).default;
        const root = createRoot(document.createElement("div"));
        ImportedIconRef.current = encode
          ? `data:image/svg+xml;base64,${window.btoa(
              root
                .render(<Icon />)
                .replace(/\r?\n|\r/g, "")
                .replace(
                  'data-reactroot=""',
                  'xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"'
                )
            )}`
          : Icon;
        if (onCompleted && mounted) {
          onCompleted(name, ImportedIconRef.current);
        }
      } catch (err) {
        if (mounted) {
          if (onError) {
            onError(err);
          }
          setError(err);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };
    importIcon();
    return () => {
      mounted = false;
    };
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
};

export default useDynamicSVGImport;
