module.exports = {
  colors: {
    // neutrals
    white: { DEFAULT: "#ffffff" },
    offwhite: { DEFAULT: "#FDFBF4" },
    gray: { DEFAULT: "#666666", light: "#EDF0F2" },
    black: { DEFAULT: "#393939" },
    // bDEFAULT:rand {color}s
    orange: { DEFAULT: "#FFA900" },
    red: { DEFAULT: "#FF2400" },
    pink: { DEFAULT: "#FF83FF" },
    teal: { DEFAULT: "#42B9BC" },
    green: { DEFAULT: "#C3D44E" },
    yellow: { DEFAULT: "#FFD812" },
    blue: { DEFAULT: "#2595DB" },
  },
  lineHeight: {
    less: ".9",
    tighter: 1.125,
  },
  fontFamily: {
    sans: ["nimbus-sans", "sans-serif"],
    extended: ["nimbus-sans-extended", "sans-serif"],
    serif: ["room-205", "serif"],
  },
  screens: {
    xxs: { max: "350px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: { raw: "(min-width: 1200px) and (min-height:900px)" }, // "1200px",
    xl: { raw: "(min-width: 1200px) and (min-height:1000px)" }, // larger than 15" macbook pro
    xxl: "2000px",
  },
  shadow: false,
};
