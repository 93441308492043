import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { Share } from "@molecules";
import { Text, ButtonAlt } from "@atoms";

const Overview = ({
  title,
  fName,
  lName,
  copy,
  backLink,
  light,
  description,
  url,
  historySection,
}) => {
  return (
    <>
      {backLink && (
        <ButtonAlt to="/" noIcon={false} reverse>
          Back to Giving Tuesday Main Page
        </ButtonAlt>
      )}
      <div className="space-y-6">
        <Text
          variant="h2"
          className={clsx({
            "text-gold": !light,
          })}
        >
          {title}
        </Text>
        <Text
          variant="sm"
          className={clsx("font-bold", {
            "text-white": !light,
          })}
        >
          {`Created by: ${fName} ${lName}`}
        </Text>
        <Text
          variant="body--large-tight"
          className={clsx("mt-6 whitespace-pre-wrap", {
            "text-white": !light,
          })}
        >
          {copy}
        </Text>
        <Text
          variant="body"
          className={clsx("mt-6", {
            "text-white": !light,
          })}
        >
          {description}
        </Text>
        <div className="mt-6 text-white">
          <ButtonAlt
            onClick={() => {
              historySection.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Learn More About Us
          </ButtonAlt>
        </div>
        <div>
          <Share body={copy} intent="fundraiser" small url={url} light links />
        </div>
      </div>
    </>
  );
};

Overview.defaultProps = {};

export default Overview;
