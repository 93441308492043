/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import lottie from "lottie-web";

const LottieAnimation = ({ url, active }) => {
  const animation = useRef();
  const [animData, setAnimData] = useState();

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setAnimData(res);
      });
  }, []);

  useEffect(() => {
    if (active && animData) {
      setTimeout(() => {
        lottie.loadAnimation({
          container: animation.current,
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: animData,
        });
      }, 500);
    }
    if (!active && lottie) {
      lottie.destroy();
    }
    return () => {
      lottie.destroy();
    };
  }, [active, animData]);
  return <div ref={animation} />;
};

export default LottieAnimation;
