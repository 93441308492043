import React from "react";

import "../../pcss/twitch.pcss";

const Twitch = ({ channel }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    const ReactTwitchEmbedVideo = require("react-twitch-embed-video");
    return (
      <div className="h-full w-full">
        <ReactTwitchEmbedVideo channel={channel} id={channel} />
      </div>
    );
  }
  return <div className="w-full" />;
};

export default Twitch;
