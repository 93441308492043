import React from "react";

import clsx from "clsx";
import { AppLink } from "@base";
import { Icon, Image, Text } from "@atoms";
import brand from "@utils/brand";

const VideoAudioTease = ({ condensed, url, image, date, title, type }) => {
  const { lakota, lgca, romero } = brand;
  return (
    <AppLink
      to={url}
      className={`group flex w-full flex-wrap text-left
      ${condensed ? "flex-col" : "-mx-3"}`}
    >
      <div className={`${!condensed ? "w-full px-3 sm:w-1/3" : "w-full"}`}>
        {image && image.url && (
          <div className="relative" style={{ paddingBottom: "56.25%" }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <Image image={image} fill />
              {lakota && (
                <div className="absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
                  <Icon
                    name="paintStrokeSmall"
                    className="stretch h-10 w-10 text-red"
                  />
                </div>
              )}
              {!lakota && (
                <div
                  className={clsx(
                    "absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105"
                  )}
                >
                  <div
                    className={clsx("h-8 w-8 rounded-full", {
                      "bg-red": lgca,
                      "bg-white": romero,
                    })}
                  />
                </div>
              )}
              <div
                className={clsx(
                  "absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105"
                )}
              >
                {type === "video" && (
                  <Icon
                    name="play"
                    className={clsx("h-6 w-6", {
                      "text-white": lgca || lakota,
                      "text-gold": romero,
                    })}
                  />
                )}
                {type === "audio" && (
                  <Icon
                    name="audio"
                    className={clsx("h-6 w-6", {
                      "text-white": lgca || lakota,
                      "text-gold": romero,
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {(!image || !image.url) && !romero && (
          <div className="relative " style={{ paddingBottom: "56.25%" }}>
            <div className="absolute inset-0 flex items-center justify-center bg-brown p-6">
              <Icon
                name="iconLakotaInverse"
                className="h-full w-full opacity-25"
                fitHeight
              />
            </div>
            <div className="absolute inset-0 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <Icon
                name="paintStrokeSmall"
                className="stretch h-10 w-10 text-red"
              />
            </div>
            <div className="absolute inset-0 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <Icon name="play" className="h-6 w-6 text-white" />
            </div>
          </div>
        )}
        {(!image || !image.url) && romero && (
          <div className="relative bg-blue" style={{ paddingBottom: "56.25%" }}>
            <div className="absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <div className="h-8 w-8 rounded-full bg-white" />
            </div>
            <div className="absolute inset-0 z-20 flex transform items-center justify-center transition duration-200 group-hover:scale-105">
              <Icon name="play" className="h-6 w-6 text-gold" />
            </div>
          </div>
        )}
      </div>
      <div className={`${!condensed ? "w-full px-3 sm:w-2/3" : "w-full"}`}>
        <Text variant="label" className="mb-2 mt-4">
          {date}
        </Text>
        <Text
          variant={romero ? "h5" : "body--large-tight"}
          className={clsx("mt-2", { "font-bold": !romero })}
        >
          {title}
        </Text>
      </div>
    </AppLink>
  );
};

VideoAudioTease.defaultProps = {};

export default VideoAudioTease;
