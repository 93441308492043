import React, { Suspense, lazy } from "react";
import { Text, CbWrapper } from "@atoms";

const Video = lazy(() => import("../atoms/Video"));

const CbVideo = ({ videoUrl, caption, indent, type, aspectRatio }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="shadow-xl">
        <Suspense fallback={<div />}>
          <Video url={videoUrl} aspectRatio={aspectRatio} />
        </Suspense>
      </div>
      {caption && (
        <Text variant="caption" className="mt-4">
          {caption}
        </Text>
      )}
    </CbWrapper>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
