import React from "react";

const Progress = ({
  totalContributionProgress,
  totalSubmissionProgress,
  targetAmount,
  progressOutOfOneHundred,
  progressType,
}) => {
  switch (progressType) {
    case "Contribution":
      return (
        <div className="my-2 px-6 text-xs font-bold">
          <div className="flex flex-wrap justify-between">
            <div>
              ${totalContributionProgress?.toLocaleString()} out of $
              {targetAmount?.toLocaleString()}
            </div>
            <div>{`${progressOutOfOneHundred}% of goal`}</div>
          </div>
          <div className="relative mt-2 h-3 w-full overflow-hidden rounded-full border border-black bg-black/10">
            <div
              className="absolute left-0 top-0 bottom-0 rounded-full bg-primary"
              style={{ width: `${progressOutOfOneHundred}%` }}
            />
          </div>
        </div>
      );
    default:
      return (
        <div className="my-2 px-6 text-xs font-bold">
          <div className="flex flex-wrap justify-between">
            <div>
              {totalSubmissionProgress?.toLocaleString()} out of{" "}
              {targetAmount?.toLocaleString()}
            </div>
            <div>{`${progressOutOfOneHundred}% of goal`}</div>
          </div>
          <div className="relative mt-2 h-3 w-full overflow-hidden rounded-full border border-black bg-black/10">
            <div
              className="absolute left-0 top-0 bottom-0 rounded-full bg-red"
              style={{ width: `${progressOutOfOneHundred}%` }}
            />
          </div>
        </div>
      );
  }
};

export default Progress;
