import React, { useMemo } from "react";
import { m } from "framer-motion";

const AnimateIn = ({
  preset,
  threshold,
  children,
  callback,
  triggerAnimation,
  delay,
  animateInOut,
  className,
}) => {
  const hasTrigger = typeof triggerAnimation !== "undefined";

  const presets = {
    fadeIn: {
      initialStyle: {
        opacity: 0,
      },
      animation: {
        opacity: 1,
      },
    },
    fadeInFast: {
      initialStyle: {
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      },
      animation: {
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
    scaleIn: {
      initialStyle: {
        opacity: 0,
        scale: 0.96,
      },
      animation: {
        opacity: 1,
        scale: 1,
      },
    },
    fadeUp: {
      initialStyle: {
        opacity: 0,
        y: "8rem",
      },
      animation: {
        opacity: 1,
        y: 0,
      },
    },
    fadeUpFast: {
      initialStyle: {
        opacity: 0,
        y: "2.5rem",
      },
      animation: {
        opacity: 1,
        y: "0",
      },
    },
    fadeUpSlow: {
      initialStyle: {
        opacity: 0,
        y: "10rem",
      },
      animation: {
        opacity: 1,
        y: 0,
      },
      duration: 0.8,
    },
  };

  const viewport = useMemo(
    () =>
      !hasTrigger
        ? {
            once: !animateInOut,
            amount: threshold,
          }
        : null,
    [hasTrigger]
  );

  const initial = useMemo(() => presets[preset].initialStyle, [preset]);

  const onAnimationEnd = useMemo(
    () => () => {
      if (callback) {
        callback();
      }
    },
    [callback]
  );

  const transition = useMemo(
    () => ({ type: "tween", easing: "easeOut", duration: 0.5, delay }),
    [preset]
  );

  const animate = useMemo(
    () =>
      hasTrigger && triggerAnimation
        ? presets[preset].animation
        : presets[preset].initialStyle,
    [preset, hasTrigger, triggerAnimation]
  );

  return (
    <m.div
      className={className}
      initial={initial}
      whileInView={!hasTrigger ? presets[preset].animation : null}
      animate={animate}
      transition={transition}
      viewport={viewport}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </m.div>
  );
};

AnimateIn.defaultProps = {
  preset: "fadeUp",
  threshold: 0.1,
  callback: null,
  triggerAnimation: undefined,
  delay: 0,
  animateInOut: false,
  className: "",
};

export default AnimateIn;
