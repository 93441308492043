import React from "react";

import brand from "@utils/brand";
import { Text, CbWrapper } from "@atoms";

const CbList = ({ copy, indent, count, type }) => {
  const { romero } = brand;
  return (
    <CbWrapper type={type} indent={indent} count={count}>
      <Text variant={romero ? "list" : "body--large-tight"}>{copy}</Text>
    </CbWrapper>
  );
};
CbList.defaultProps = {};

export default CbList;
