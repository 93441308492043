import React from "react";

import clsx from "clsx";
import { AppLink } from "@base";
import brand from "@utils/brand";

const PrimaryNav = ({ links, inverse }) => {
  const { romero } = brand;

  return (
    <ul className={clsx("flex", { "-mx-2": !romero })}>
      {links &&
        links.map(link => (
          <li
            key={link.uid}
            className={clsx({ "px-2": !romero }, { "pr-4 md:pr-8": romero })}
          >
            <AppLink
              to={link.link}
              className={clsx({
                "underline-hover py-1 text-xs font-bold uppercase tracking-0.5 transition duration-200":
                  !romero,
                "py-1 text-xs font-medium tracking-wide transition duration-200 hover:text-gold":
                  romero,
                "text-white": inverse,
                "text-blue": !inverse && romero,
                "text-black": !inverse && !romero,
              })}
              activeClassName={clsx({
                "underline-red": !romero,
                "underline-gold": romero,
              })}
            >
              {link.text}
            </AppLink>
          </li>
        ))}
    </ul>
  );
};

PrimaryNav.defaultProps = {};

export default PrimaryNav;
