import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { AnimateText, Text } from "@atoms";

const HighlightedText = ({
  tag: Tag,
  use,
  text,
  highlightText,
  highlightColor,
  highlightStyle,
  animate,
  disableAnimationWidth,
  centered,
  className,
  delay,
  style,
  ...rest
}) => {
  if (highlightText && text.includes(highlightText)) {
    const segments = text.split(highlightText);

    return (
      <Text
        {...rest}
        className={clsx(className, {
          "text-center": centered,
        })}
      >
        {/* Render text before the highlight */}
        {segments[0].length > 0 && (
          <span className="font-sans">{segments[0]}</span>
        )}
        {/* If we're animating the highlighted text, then render each letter individually, else render whole word */}
        {/* TODO: have to check if animate is undefined, because if we dynamically change between true and false,
        then the text jumps around. Should find a fix for this. */}
        {animate !== undefined ? (
          <AnimateText
            animate={animate}
            text={highlightText}
            className={clsx(
              highlightColor,
              highlightStyle,
              {
                "block text-center font-heading": centered,
              },
              className
            )}
            delay={delay}
          />
        ) : (
          <span
            {...rest}
            className={clsx(className, highlightStyle, highlightColor)}
          >
            {highlightText}
          </span>
        )}
        {/* Render text after the highlight */}
        {segments[1].length > 0 && (
          <span className="font-sans">{segments[1]}</span>
        )}
      </Text>
    );
  }

  return (
    <Text tag={Tag} {...rest} className={className} preserveWidows>
      {text}
    </Text>
  );
};

HighlightedText.defaultProps = {
  use: null,
  highlightColor: "",
  highlightStyle: "italic",
  animate: undefined,
  disableAnimationWidth: 1490,
  centered: false,
  className: "",
  style: {},
};

export default HighlightedText;
