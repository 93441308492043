export const buildUrl = (url, params) =>
  `${url.replace(
    process.env.GATSBY_ASSET_BASE_URL,
    "/images"
  )}?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;

export const defaultParams = (eager, ar, pr = 1) => ({
  auto: eager ? "compress" : "compress,format",
  fit: eager ? "max" : "crop",
  q: pr > 1.67 ? 60 : 80,
  crop: "faces",
  w: 360, // from state
  h: parseInt(ar ? 360 : 360 * ar, 10), //  from state
  dpr: pr > 2 ? 2 : pr,
});

export const getAltTag = async (imageUrl, alt) => {
  if (alt) {
    return false;
  }

  try {
    const thing = await fetch(`/api/cloudsight`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageUrl }),
    });
    const response = await thing.json();
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
};
