import React, { useState, useEffect, useRef } from "react";
import { useAppState } from "@state";
import { Text, Container, Button } from "@atoms";
import { AppLink } from "@base";
// eslint-disable-next-line import/no-cycle
import { SocialLinks } from "@molecules";
import clsx from "clsx";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { m } from "framer-motion";

const SiteNav = ({ data }) => {
  const [, setChapters] = useState();
  const [, setChapter] = useState();
  const {
    heading,
    copy,
    primaryNav,
    secondaryNav,
    chapters: navChapters,
  } = data;

  const [{ layout, ec }, dispatch] = useAppState();
  const { theme, navOpen: open } = layout;

  // the component itself
  const [prevTheme, setPrevTheme] = useState(theme);

  const navEl = useRef();

  useEffect(() => {
    setChapters(ec.chapters);
    setChapter(ec.nav.chapter);
  }, [ec.chapters, ec.nav.chapter]);

  useEffect(() => {
    if (!open) {
      setPrevTheme(theme);
    }
  }, [theme]);

  // whethere there are any activist codes
  useEffect(() => {
    if (open) {
      disableBodyScroll(navEl.current);
      if (prevTheme === "dark") {
        dispatch({ type: "setThemeLight" });
      }
    } else {
      enableBodyScroll(navEl.current);
      if (prevTheme === "dark") {
        dispatch({ type: "setThemeDark" });
      }
    }
  }, [open]);

  const variants = {
    visible: {
      display: "block",
      // y: 0,
      opacity: 1,
    },
    hidden: {
      display: "hidden",
      // y: -100,
      opacity: 0,
    },
  };

  return (
    <m.div
      variants={variants}
      ref={navEl}
      animate={open ? "visible" : "hidden"}
      className={clsx(
        "absolute left-0 right-0 top-0 z-40 hidden min-h-screen bg-white p-6 text-white sm:z-[100]",
        { "pointer-events-none": !open }
      )}
    >
      <div className="flex h-full flex-grow flex-col sm:flex-row">
        <div
          className={clsx(
            "absolute bottom-0 left-0 top-0 z-0 hidden w-1/2 w-1/2 bg-blue transition duration-200 sm:block"
          )}
          // style={{ background: chapters?.[chapter]?.primary }}
        >
          {/* placeholder */}
        </div>

        <div className="relative z-10 bg-red sm:h-full sm:w-1/2 sm:bg-transparent">
          <Container className="h-full">
            <div className="-mx-6 flex h-full flex-wrap items-center pr-8 sm:pr-0 lg:-mx-12">
              <div className="hidden h-full w-full flex-col p-6 pt-24 sm:flex sm:p-12 sm:pt-32 lg:px-12">
                <div className="SiteNav--animate">
                  <Text
                    tag="h2"
                    className="font-extended block hidden text-2xl leading-tight sm:block"
                    richText={false}
                  >
                    {heading}
                  </Text>
                  {copy && (
                    <Text className="font-sans mt-3 hidden leading-normal sm:block">
                      {copy}
                    </Text>
                  )}
                </div>
                {primaryNav && (
                  <div className="SiteNav--animate font-extended hidden font-bold uppercase tracking-1 sm:mt-12 sm:block">
                    {primaryNav.map(link => {
                      return (
                        <div key={link.uid} className="mb-6 text-xs sm:text-sm">
                          <AppLink
                            to={link.link}
                            className="py-2 font-bold uppercase transition duration-200 hover:opacity-50"
                          >
                            {link.text}
                          </AppLink>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="SiteNav--animate font-extended mb-4 hidden font-bold uppercase tracking-1 sm:block">
                  {secondaryNav &&
                    secondaryNav.map(link => {
                      return (
                        <div key={link.uid} className="mb-6 text-lg sm:text-sm">
                          <AppLink
                            to={link.link}
                            className="font-extended font-bold uppercase transition duration-200 hover:opacity-50"
                          >
                            {link.text}
                          </AppLink>
                        </div>
                      );
                    })}
                </div>
                <div className="my-3 flex w-full items-center justify-start">
                  <SocialLinks color="orange" small />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="font-serif absolute relative bottom-0 z-20 mt-auto flex flex-grow flex-col justify-center bg-white pt-24 xxs:pt-20 sm:right-0 sm:top-0 sm:mt-0 sm:w-1/2 sm:px-6 sm:py-3 sm:pt-0">
          {navChapters?.map((link, i) => {
            if (link?.colors?.primary) {
              return (
                <div key={link.uid}>
                  <AppLink
                    to={link.url}
                    className="leading-tighter flex items-center py-1 text-xl transition duration-200 sm:mb-3 sm:py-2 sm:text-2xl md:mb-4 lg:mb-5"
                  >
                    <span
                      className="flex items-center transition duration-200"
                      style={{ color: link.colors.primary }}
                      onMouseEnter={e => {
                        e.target.style.color = link.colors.secondary;
                      }}
                      onMouseLeave={e => {
                        setTimeout(() => {
                          e.target.style.color = link.colors.primary;
                        });
                      }}
                    >
                      <span className="pointer-events-none mr-4 flex w-10 flex-shrink-0 items-center justify-center text-center text-3xl sm:text-5xl">
                        {i}
                      </span>
                      <span className="pointer-events-none">{link.text}</span>
                    </span>
                  </AppLink>
                </div>
              );
            }
            return null;
          })}
        </div>
        {/* xs screens */}
        <div className="my-3 block flex h-full w-full items-center justify-center sm:hidden">
          <Button
            size="sm"
            color="red"
            to={data?.button?.link || "https://action.greencal.org"}
          >
            {data?.button?.text || "Take Action"}
          </Button>
        </div>
        <div className="my-3 block flex h-full w-full items-center justify-center sm:hidden lg:px-12">
          <SocialLinks color="orange" small />
        </div>
        <div className="SiteNav--animate my-3 flex w-full flex-wrap items-center justify-center pb-0 text-sm font-bold uppercase tracking-1 text-blue sm:hidden sm:w-3/5 lg:px-12">
          {primaryNav?.map(link => {
            return (
              <div key={link.uid} className="font-extended mb-3 px-2">
                <AppLink
                  to={link.link}
                  className="underline-hover py-2 font-bold uppercase transition duration-200"
                >
                  {link.text}
                </AppLink>
              </div>
            );
          })}
          {secondaryNav &&
            secondaryNav.map(link => {
              return (
                <div key={link.uid} className="font-extended mb-3 px-2">
                  <AppLink
                    to={link.link}
                    className="underline-hover font-bold uppercase transition duration-200"
                  >
                    {link.text}
                  </AppLink>
                </div>
              );
            })}
        </div>
      </div>
    </m.div>
  );
};

SiteNav.defaultProps = {
  open: false,
};

export default SiteNav;
