import { getCookie, setCookie } from "@utils/cookies";

const hasGdprConsent = appName => {
  if (typeof window !== "undefined") {
    if (getCookie(`${appName}_gdpr`) === "true") {
      // extend consent
      setCookie(`${appName}_gdpr`, true);
      return true;
    }
  }
  return false;
};

const setGdprConsent = appName => {
  setCookie(`${appName}_gdpr`, true);
};

export default { hasGdprConsent, setGdprConsent };
