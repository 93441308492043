import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Icon, Text, Square } from "@atoms";

const ButtonIcon = ({ cta, icon }) => {
  const { romero } = brand;
  return (
    <Square
      className={clsx("group bg-white text-center shadow-xl transition", {
        "text-red duration-200 hover:bg-red hover:text-cream": !romero,
        "text-blue duration-200 hover:bg-blue hover:text-white": romero,
      })}
    >
      <div className="flex h-full flex-col items-center justify-center p-6 lg:p-12">
        <Text variant="h2" className="hidden md:block">
          {cta}
        </Text>
        <Text variant="h3" className="md:hidden">
          {cta}
        </Text>
        <div
          className={clsx({
            "text-gray group-hover:text-white": !romero,
            "text-gold": romero,
          })}
        >
          <Icon name={icon} fitHeight className="mt-6 h-10 w-auto lg:h-12" />
        </div>
      </div>
    </Square>
  );
};

ButtonIcon.defaultProps = {};

export default ButtonIcon;
