import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Image from "./Image";

const InputRadioImages = ({
  options,
  formState,
  name,
  required,
  className,
  label,
}) => {
  const [form, setForm] = formState;
  const [option, setOption] = useState(options[0].value);

  useEffect(() => {
    setForm({ ...form, [name]: option });
  }, [option]);

  useEffect(() => {
    const value = form[name];
    if (value && value !== option) {
      setOption(value);
    }
  }, [form]);

  return (
    <div className={clsx("relative flex w-full flex-col", className)}>
      <label
        htmlFor={name}
        className="mb-1 flex items-center text-xxs font-bold uppercase text-black"
      >
        {label}
        {required && (
          <span className="ml-1 inline-flex text-base text-red">*</span>
        )}
      </label>
      <input
        type="text"
        hidden
        name={name}
        value={option}
        onChange={e => {
          setForm({ ...form, [name]: e.target.value });
        }}
        required={required}
        className="absolute h-0 w-0 overflow-hidden"
      />
      <ul className="grid w-full grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {options?.map(({ label: image, value }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i}>
            <button
              type="button"
              className={clsx("relative h-24 w-full border-3 p-px", {
                "border-red": option === value,
                "border-white": option !== value,
              })}
              onClick={() => setOption(value)}
            >
              <Image image={image} alt={`Option ${i + 1}`} fill />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

InputRadioImages.defaultProps = {};

export default InputRadioImages;
