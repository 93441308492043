import React from "react";

import clsx from "clsx";
import { AppLink } from "@base";
// eslint-disable-next-line import/no-cycle
import { Text, Image, Icon } from "@atoms";
import _brand from "@utils/brand";
import { m } from "framer-motion";

const BlogTease = ({
  siteId,
  uri,
  image,
  url,
  siteId: program,
  categories,
  title,
  showExcerpt,
  description,
  date,
  showProgram,
  stack,
  big,
}) => {
  const { romero, lgca, lakota, brand } = _brand;

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const options = {
    4: "romero",
    5: "lgca",
    7: "lakota",
  };
  const programs = {
    4: "Romero Institute",
    5: "Let's Green California",
    7: "Lakota People's Law Project",
  };

  const animation = {
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
  };

  return (
    <m.div
      layout
      transition={{ duration: 0.25 }}
      initial={animation.exit}
      exit={animation.exit}
      animate={animation.enter}
    >
      <AppLink
        mode="sync"
        to={url || uri}
        className="group -mx-2 flex flex-wrap sm:-mx-3"
      >
        <div
          className={clsx("px-2 sm:px-3", {
            "mb-4": !romero,
            "mb-4 md:mb-6": romero,
            "w-full sm:w-1/3": !stack,
            "w-full": stack,
          })}
        >
          <div className="relative">
            {image?.url && (
              <div className="absolute inset-0 z-10">
                <Image
                  fill
                  image={image}
                  aspectRatio={stack ? [16, 9] : [4, 3]}
                />
              </div>
            )}
            <div
              className={clsx("group z-0 flex items-center justify-center", {
                "aspect-[16/9]": stack,
                "aspect-[4/3]": !stack,
                "bg-brown": lakota,
                "bg-red": lgca,
                "bg-romero": romero && siteId === 4,
                "bg-lakota": romero && siteId === 7,
                "bg-lgca": romero && siteId === 5,
                "bg-blue": romero && !siteId,
              })}
            >
              <Icon
                name={`icon${capitalize(options[program])}Inverse`}
                className={clsx("h-12 w-12", {
                  "transition duration-200 group-hover:scale-105": romero,
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={clsx("px-2 sm:px-3", {
            "w-full sm:w-2/3": !stack,
            "w-full": stack,
          })}
        >
          {romero && (
            <Text
              variant="xs"
              className="font-medium uppercase tracking-widest text-gold"
            >
              {date}
            </Text>
          )}
          {categories && !romero && (
            <Text
              variant="label"
              className={clsx("flex flex-wrap font-bold uppercase", {
                "font-extended text-blue": lgca,
                "text-red": lakota,
              })}
            >
              {!!categories.length && categories.map(c => c.title).join(", ")}
              {(!categories || !categories.length) && "Article"}
            </Text>
          )}
          <Text
            variant={big ? "h4" : "h5"}
            className={clsx({
              "my-2 font-bold": !romero,
              "mt-2 md:mt-4": romero,
            })}
          >
            {title}
          </Text>
          {!romero && (
            <Text variant="xs" className="mt-2 text-gray">
              {`Date: ${date}`}
            </Text>
          )}
          {romero && showProgram && (
            <div className="mt-4 flex items-center">
              <Icon
                name={`icon${capitalize(options[program] || brand)}`}
                className={clsx("mr-1 h-4 w-4", {
                  "text-blue": options[program] === "romero",
                })}
              />
              <Text
                variant="xs"
                className={clsx("text-gray-light", {
                  "font-bold": !romero,
                  "font-medium tracking-wide": romero,
                })}
              >
                {programs[program]}
              </Text>
            </div>
          )}
          {showExcerpt && (
            <Text variant="body--small" className="mt-2">
              {description}
            </Text>
          )}
        </div>
      </AppLink>
    </m.div>
  );
};

BlogTease.defaultProps = {};

export default BlogTease;
