import React, { useState, useEffect } from "react";
import { Text, Icon, Container, Button, ButtonAlt } from "@atoms";
import { useAppState } from "@state";
import removeUrlParam from "@utils/removeUrlParam";

// eslint-disable-next-line import/no-cycle
import ShareModal from "@organisms/ShareModal";

const StatusBanner = ({ editKey, slug }) => {
  const [, dispatch] = useAppState();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch({
      type: "setKey",
      key: editKey,
    });
    removeUrlParam("edit");
  }, []);
  return (
    <>
      <div className="flex items-center bg-gold py-3">
        <Container>
          <div className="flex items-center justify-between">
            <Text className="font-serif text-xs font-bold">
              Your Fundraiser is now live!
            </Text>
            <ButtonAlt
              to={`/edit-fundraiser?key=${editKey}&slug=${slug}`}
              noIcon={false}
              icon="edit"
              small
              className="ml-auto"
            >
              Edit
            </ButtonAlt>
            <Button
              size="sm"
              onClick={() => setModalOpen(true)}
              className="ml-6"
            >
              <span className="flex items-center">
                Share
                <Icon name="share" className="ml-2 h-3 w-3" />
              </span>
            </Button>
          </div>
        </Container>
      </div>
      <ShareModal
        url={`${process.env.GATSBY_SITE_URL}/${slug}`}
        openState={[modalOpen, setModalOpen]}
      />
    </>
  );
};

StatusBanner.defaultProps = {};

export default StatusBanner;
