import React, { useState, useRef, useEffect } from "react";

import clsx from "clsx";
import brand from "@utils/brand";
// import { uid } from "react-uid";
import Arrow from "@svg/arrowShort.svg";
import Chevron from "@svg/chevron.svg";
import { m } from "framer-motion";

const Dropdown = ({ model, options, placeholder, inverse, size, sans }) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = model;
  const dropdown = useRef();

  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    setIsActive(false);
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownVariants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  const arrowVariants = {
    open: {
      rotate: 90,
      y: -2,
    },
    closed: {
      rotate: 0,
      y: 0,
    },
  };

  // eslint-disable-next-line global-require

  const { romero, lgca } = brand;

  let iconSize;

  if (!romero) {
    switch (size) {
      case "xs":
        iconSize = "1";
        break;
      case "sm":
        iconSize = "2";
        break;
      case "md":
        iconSize = "3";
        break;
      case "lg":
        iconSize = "4";
        break;
      default:
        iconSize = "3";
        break;
    }
  } else {
    switch (size) {
      case "xs":
        iconSize = "3";
        break;
      case "sm":
        iconSize = "4";
        break;
      case "md":
        iconSize = "5";
        break;
      case "lg":
        iconSize = "6";
        break;
      default:
        iconSize = "6";
        break;
    }
  }

  let bgColor = "white";
  let textColor = "black";

  if (inverse) {
    bgColor = "black";
    textColor = "white";
  }

  return (
    <div
      className={clsx("relative", {
        "font-sans text-xs uppercase": sans,
        "font-serif": !sans,
        "font-medium tracking-widest": romero && sans,
        "font-bold": !romero && sans,
      })}
    >
      <button
        type="button"
        onClick={() => setIsActive(!isActive)}
        className={clsx(
          `flex items-center text-${size} w-full justify-between `,
          {
            "border-red": inverse && !romero,
            "border-grey-light": !inverse,
            border: !sans,
            "px-3 py-1": size === "xs",
            "px-4 py-2": size !== "xs",
            "bg-black text-white": inverse,
            "bg-white text-black": !inverse,
          }
        )}
      >
        <span className="mr-2 whitespace-nowrap text-left">
          {value != null && value !== "" ? options[value] : placeholder}
        </span>
        {/* dropdown Icon */}
        <m.span
          initial="closed"
          animate={isActive ? "open" : "closed"}
          variants={arrowVariants}
          className={clsx(`icon w-${iconSize} block h-${iconSize} pl-1`, {
            "text-red": !romero,
            "text-blue": romero,
          })}
        >
          {!romero && !lgca && <Arrow />}
          {(romero || lgca) && <Chevron />}
        </m.span>
      </button>
      <div className="relative -mt-px">
        <m.ul
          ref={dropdown}
          initial="closed"
          animate={isActive ? "open" : "closed"}
          variants={dropdownVariants}
          className={clsx(
            `absolute left-0 z-40 text-${size} bg-${bgColor} text-${textColor} h-0 overflow-hidden whitespace-nowrap`,
            {
              "border-b border-l border-r": inverse,
              "shadow-xl": isActive,
              "shadow-none": !isActive,
              "border-red": !romero,
            }
          )}
        >
          {placeholder.length > 1 && (
            <li>
              <button
                type="button"
                className={`w-full px-4 py-2 text-${size} text-left opacity-50`}
                disabled
              >
                {placeholder}
              </button>
            </li>
          )}
          {options.map((option, i) => {
            return (
              <li key={option.uid ? option.uid : option + String(i)}>
                <button
                  onClick={() => setValue(i)}
                  type="button"
                  className={clsx(
                    "w-full px-4 py-2 text-left transition duration-200",
                    {
                      "hover:bg-red": !romero,
                      "hover:bg-blue hover:text-white": romero,
                    }
                  )}
                >
                  {option?.label || option}
                </button>
              </li>
            );
          })}
        </m.ul>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
  blue: false,
};

export default Dropdown;
