import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Text, CbWrapper } from "@atoms";

const CbBlockQuote = ({ quote, citation, indent, type }) => {
  const { romero } = brand;
  return (
    <CbWrapper type={type} indent={indent}>
      <div className="max-w-lg md:-ml-6 lg:-ml-12 lg:pb-2 lg:pt-4">
        <Text
          variant="blockquote"
          className={clsx("mb-3", {
            "font-serif font-bold": !romero,
          })}
        >
          {quote}
        </Text>
        {citation && (
          <Text
            variant={romero ? "body" : "body--large-tight"}
            className={clsx("citation mt-4", {
              "font-bold uppercase tracking-widest": romero,
            })}
          >
            {citation}
          </Text>
        )}
      </div>
    </CbWrapper>
  );
};

CbBlockQuote.defaultProps = {};

export default CbBlockQuote;
