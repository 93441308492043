import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { SupporterModal } from "@molecules";
import { Text, Image, Icon } from "@atoms";
import { useAppState } from "@state";

const CardSupporter = ({ type, name, title, image, bio, link }) => {
  const [, dispatch] = useAppState();
  const attributes = {
    person: {
      aspectRatio: [1, 1],
      params: {
        fit: "facearea",
        facepad: 10,
      },
      round: true,
    },
    organization: {
      round: false,
      params: {
        fit: "max",
      },
    },
  };

  const openModal = () => {
    dispatch({
      type: "openModal",
      background: "bg-black",
      // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
      content: React.memo(() => (
        <SupporterModal
          image={image}
          bio={bio}
          link={link}
          params={{
            aspectRatio: attributes[type].aspectRatio,
            ixParams: attributes[type].params,
            round: attributes[type].round,
            position: "center",
            fill: type === "person",
            fitHeight: true,
            objectFit: type !== "person" ? "contain" : "cover",
          }}
        />
      )),
    });
  };

  return (
    <button
      className={clsx(
        "relative flex w-full flex-grow flex-col items-center justify-center bg-white p-6 shadow-lg",
        {
          "cursor-default": !bio && !link,
        }
      )}
      type="button"
      onClick={openModal}
    >
      {bio && (
        <div className="absolute right-0 top-0 z-10 flex items-center justify-center p-3 sm:p-6">
          <Icon
            name="info"
            className="h-4 w-4 cursor-pointer text-blue opacity-75"
          />
        </div>
      )}
      <div
        className={clsx({
          "relative w-2/3 overflow-hidden rounded-full p-2": type === "person",
          "w-2/3": type !== "person",
        })}
      >
        <div className="relative flex aspect-square w-full">
          {image && (
            <Image
              image={image}
              aspectRatio={attributes[type].aspectRatio}
              ixParams={attributes[type].params}
              round={attributes[type].round}
              position="center"
              fill
              fitHeight
              objectFit={type !== "person" ? "contain" : "cover"}
            />
          )}
        </div>
      </div>
      <div className="mt-2 space-y-3 text-center">
        <Text className="font-extended leading-tighter text-lg font-bold">
          {name}
        </Text>
        {type === "person" && (
          <Text variant="label" className="text-red">
            {title}
          </Text>
        )}
      </div>
    </button>
  );
};

CardSupporter.defaultProps = {};

export default CardSupporter;
