import React from "react";
import clsx from "clsx";
import brand from "@utils/brand";
import { Text, Image } from "@atoms";

const PullQuote = ({ color, image, heading, quote, attribution }) => {
  const { lakota, romero } = brand;
  return (
    <aside
      className={clsx("w-full p-4 md:w-1/4", {
        "bg-red": lakota && color !== "brown",
        "bg-cream": lakota && color === "brown",
        "bg-blue": romero && color !== "blue",
        "bg-blue-light": romero && color === "blue",
      })}
    >
      <div
        className={clsx(
          "flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 md:flex-col md:space-y-4",
          {
            "rounded-xl border-2 p-4": romero,
            "border-white text-white": romero && color !== "blue",
            "border-blue-dark text-blue-dark": romero && color === "blue",
            "text-white": lakota && color !== "brown",
            "text-black": lakota && color === "brown",
          }
        )}
      >
        {image && (
          <div className="w-40 flex-shrink-0 pr-0 sm:pr-4 md:w-full md:pr-0">
            <Image image={image} />
          </div>
        )}
        <div
          className={clsx("flex-grow md:w-full", {
            "reduce-spacing": lakota,
          })}
        >
          {heading && (
            <Text variant="body--large" className="mb-2 font-bold">
              {heading}
            </Text>
          )}
          <Text
            variant={romero ? "pullquote" : "body"}
            className={clsx({
              "!font-serif": lakota,
            })}
          >
            {quote}
          </Text>
          {attribution && (
            <Text variant="quoted" className="attribution mt-2">
              {attribution}
            </Text>
          )}
        </div>
      </div>
    </aside>
  );
};

PullQuote.defaultProps = {};

export default PullQuote;
