import React, { useEffect, useState } from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { Share } from "@molecules";
import { Text, Icon } from "@atoms";
import { useAppState } from "@state";

const SupporterModal = ({ openState, url }) => {
  const [, dispatch] = useAppState();
  const [copied, setCopied] = useState();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [modalOpen, setModalOpen] = openState || useState(false);

  useEffect(() => {
    if (modalOpen) {
      dispatch({
        type: "openModal",
        background: "bg-black",
        // eslint-disable-next-line react/display-name
        content: React.memo(() => (
          <div className="fixed inset-0 z-50 hidden items-center justify-center p-4 opacity-0 sm:p-6 md:p-10 lg:p-12 xl:p-24">
            <button
              type="button"
              className="absolute inset-0 z-0 block h-full w-full text-transparent"
              onClick={() => setModalOpen(false)}
            >
              <span className="absolute inset-0 bg-black opacity-90" />
              close
            </button>
            <div className="z-10 mx-auto w-full max-w-md overflow-y-auto opacity-0">
              <div
                className={clsx(
                  "relative flex flex-col items-center justify-center bg-cream p-4 shadow-xl sm:p-6 md:p-10 lg:p-12",
                  {}
                )}
              >
                <div className="absolute right-0 top-0 block p-3">
                  <button
                    type="button"
                    className="text-red"
                    onClick={() => setModalOpen(false)}
                  >
                    <Icon name="close" className="h-4 w-4" />
                  </button>
                </div>
                <Text variant="h5">Share Your Fundraiser</Text>
                <Text variant="label" className="my-3 text-red">
                  Click to copy and share your URL
                </Text>
                <input
                  onClick={() => {
                    navigator.clipboard.writeText(url);
                    setCopied(true);
                  }}
                  value={url}
                  placeholder="share URL"
                  className="border-1 block w-full border-gray p-2"
                />
                {copied && (
                  <span className="mt-2 text-center text-red">Copied!</span>
                )}
                <Share
                  url={url}
                  intent="personal"
                  compact
                  small
                  className="mt-3"
                />
              </div>
            </div>
          </div>
        )),
      });
    }
  }, [modalOpen]);
};

SupporterModal.defaultProps = {};

export default SupporterModal;
