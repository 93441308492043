import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbCopy = ({ copy, indent, type, inverse }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text inverse={inverse} className="max-w-[35rem]" variant="body">
        {copy}
      </Text>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
