import React from "react";

import clsx from "clsx";

const Square = ({ children, className: _className }) => {
  return (
    <div
      className={clsx("relative w-full", _className)}
      style={{ paddingBottom: "100%" }}
    >
      <div className={clsx("absolute inset-0 z-10 flex flex-col", {})}>
        {children}
      </div>
    </div>
  );
};

Square.defaultProps = {};

export default Square;
