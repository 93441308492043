import React from "react";

import { Text, CbWrapper, Image, ButtonAlt } from "@atoms";

const CbResources = ({ resourceEntries, type }) => {
  return (
    <CbWrapper type={type}>
      {resourceEntries.map(r => (
        <div key={r.uid} className="my-10 border-l-3 border-gold pl-4">
          <div className="flex items-start gap-4">
            <div className="w-1/4">
              <Image image={r.image} aspectRatio={[1, 1]} fitHeight />
            </div>
            <div className="w-3/4">
              <Text variant="label">{r.date}</Text>
              <Text variant="h5" className="font-serif my-3">
                {r.title}
              </Text>
              <ButtonAlt to={r.url} noIcon={false} color="gold">
                Read More
              </ButtonAlt>
            </div>
          </div>
        </div>
      ))}
    </CbWrapper>
  );
};

CbResources.defaultProps = {};

export default CbResources;
