import React from "react";

import useLgcaDefaultsData from "@staticQueries/lgca/DefaultsQuery";
import useLakotaDefaultsData from "@staticQueries/lakota/DefaultsQuery";
import useLgcaAcDefaultsData from "@staticQueries/lgcaAc/DefaultsQuery";
import useLakotaAcDefaultsData from "@staticQueries/lakotaAc/DefaultsQuery";
import useRomeroDefaultsData from "@staticQueries/romero/DefaultsQuery";
import { buildUrl } from "@utils/imageUtils";
import brand from "@utils/brand";

const Meta = ({
  title: _title,
  metaTitle: _metaTitle,
  metaDescription,
  metaImage: _metaImage,
  twitterDescription: _twitterDescription,
  twitterTitle: _twitterTitle,
  twitterImage,
  url,
  slug,
}) => {
  const meta = {
    lakota: useLakotaDefaultsData().meta,
    lakotaAc: useLakotaAcDefaultsData().meta,
    romero: useRomeroDefaultsData().meta,
    lgca: useLgcaDefaultsData().meta,
    lgcaAc: useLgcaAcDefaultsData().meta,
    lakotaGc: {},
  };
  const { siteTitle, metaImage: defaultMetaImage } = meta[brand.site] || {};

  if (typeof window === "undefined") {
    let errors = "";
    if (!_title) errors += "title ";
    if (metaDescription) errors += "metaDescription ";
    // if (_metaImage) errors += "_metaImage ";
    if (url) errors += "url";
    if (errors?.length) {
      // eslint-disable-next-line no-console
      console.log("\x1b[31m", `${slug} missing meta: ${errors}`);
    }
  }

  const metaTitle = _metaTitle || `${_title} - ${siteTitle}`;
  // const metaImage = metaImage

  const metaImage = _metaImage || defaultMetaImage || false;

  let metaImageUrl = "";
  let twitterImageUrl = "";

  if (metaImage && metaImage?.url) {
    // configure default params
    let ixParams = {
      auto: "compress,format",
      fit: "max",
      crop: "faces, edges, entropy",
      q: 50,
    };

    const { focalPoint } = metaImage;

    // if focal point is set, overwrite crop settings
    if (focalPoint) {
      ixParams = {
        ...ixParams,
        fit: "crop",
        crop: "focalpoint",
        "fp-x": focalPoint[0],
        "fp-y": focalPoint[1],
      };
    }

    metaImageUrl = metaImage
      ? buildUrl(metaImage?.url, {
          ...ixParams,
          w: 1200,
          h: 1200,
        })
      : null;

    metaImageUrl =
      process.env.GATSBY_SITE_URL.replace(/\/$/, "") + metaImageUrl;
  }

  if (twitterImage && twitterImage?.url) {
    // configure default params
    let ixParams = {
      auto: "compress,format",
      fit: "max",
      crop: "faces, edges, entropy",
      q: 50,
    };

    const { focalPoint } = twitterImage;

    // if focal point is set, overwrite crop settings
    if (focalPoint) {
      ixParams = {
        ...ixParams,
        fit: "crop",
        crop: "focalpoint",
        "fp-x": focalPoint[0],
        "fp-y": focalPoint[1],
      };
    }

    if (twitterImage?.url) {
      twitterImageUrl = twitterImage
        ? process.env.GATSBY_SITE_URL +
          buildUrl(twitterImage?.url, {
            ...ixParams,
            w: 1200,
            h: 1200,
          })
        : null;
    } else {
      twitterImageUrl = metaImageUrl;
    }
  }

  const twitterTitle = _twitterTitle || metaTitle;
  const twitterDescription = _twitterDescription || metaDescription;
  twitterImageUrl = twitterImageUrl || metaImageUrl;

  return (
    <>
      <title>{metaTitle}</title>
      {/* <!-- Short description of the document (limit to 150 characters) --> */}
      {/* <!-- This content *may* be used as a part of search engine results. --> */}
      <meta name="description" content={metaDescription} />

      {/* <!-- Control the behavior of search engine crawling and indexing --> */}
      {/* <!-- All Search Engines --> */}
      <meta name="robots" content="index,follow" />
      {/* <!-- Google Specific --> */}
      <meta name="googlebot" content="index,follow" />

      {/* og */}
      <meta property="og:title" content={metaTitle} />
      {metaImageUrl && metaImageUrl && metaImageUrl.length > 2 && (
        <meta property="og:image" content={metaImageUrl.replace(/\s/g, "")} />
      )}
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" name="og:type" content="website" />
      <meta
        property="og:url"
        content={`${url}${url?.slice(-1) !== "/" ? "" : "/"}`}
      />
      <meta property="fb:app_id" content={process.env.GATSBY_FACEBOOK_APP_ID} />

      {/* twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={twitterTitle || metaTitle} />
      <meta
        property="twitter:url"
        content={`${url}${url?.slice(-1) !== "/" ? "" : "/"}`}
      />
      <meta
        name="twitter:description"
        content={twitterDescription || metaDescription}
      />
      {(twitterImageUrl || metaImageUrl) && (
        <meta
          name="twitter:image"
          content={(twitterImageUrl || metaImageUrl).replace(/\s/g, "")}
        />
      )}
    </>
  );
};

Meta.defaultProps = {};

export default Meta;
