import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import { SiteNav, PrimaryNav } from "@molecules";
import { Icon, Container, Button } from "@atoms";
import { useAppState } from "@state";
import useRomeroHeaderData from "@staticQueries/romero/HeaderQuery";

const RomeroHeader = () => {
  const data = useRomeroHeaderData();
  const { button } = data;

  const [{ layout }, dispatch] = useAppState();
  const { hideNav } = layout;

  return (
    <>
      <SiteNav data={data} />
      <header
        className={clsx(
          "absolute left-0 right-0 top-0 z-40 w-full bg-white sm:z-[100]",
          {}
        )}
      >
        <Container>
          <div className="flex h-20 items-center">
            <div className="pr-3 sm:pr-6">
              <AppLink
                to="/"
                className="relative block h-full w-44 sm:w-64"
                name="Romero Institute home"
              >
                <Icon
                  name="romeroLogoHorizontal"
                  className="absolute inset-0 h-auto w-full transition duration-200"
                />
              </AppLink>
            </div>
            <div className="font-sans ml-auto hidden pl-6 lg:flex lg:items-center">
              <PrimaryNav links={data.primaryNav} />
              {button?.url && (
                <div className="hidden sm:block">
                  <Button
                    size="sm"
                    rounded
                    color="gold"
                    outline
                    icon
                    to={button.url || "https://romeroinstitute.org/donate"}
                  >
                    {button.text || "Give"}
                  </Button>
                </div>
              )}
            </div>
            {/* TODO: Get from globals */}
            <div
              className={clsx(
                "ml-auto flex items-center pl-6 text-sm font-medium text-blue transition duration-200 lg:hidden",
                {
                  "pointer-events-none opacity-0": hideNav,
                }
              )}
            >
              <div>
                <button
                  type="button"
                  className="inline-flex items-center gap-2 text-xs sm:text-sm"
                  onClick={() => {
                    dispatch({ type: "toggleNav" });
                  }}
                >
                  <span>Menu</span>
                  <Icon name="barsAlt" className={clsx("h-8 w-8")} />
                </button>
              </div>
            </div>
            {/* <div className="px-3 sm:px-6 hidden">mobile nav button</div> */}
          </div>
        </Container>
      </header>
    </>
  );
};

export default RomeroHeader;
