import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Text, Icon, Button } from "@atoms";

const CardIcon = ({ icon, heading, copy, link }) => {
  const { romero } = brand;
  return (
    <div className="flex h-full flex-col items-center bg-white p-3 text-center shadow-xl lg:p-6">
      <div>
        {/* TODO: fix fill on icons once approved */}
        <Icon
          name={icon}
          fitHeight
          className={clsx("h-10 w-10", {
            "text-red-light": !romero,
            "text-blue": romero,
          })}
        />
      </div>
      <div className="my-3">
        <Text
          variant="h4"
          className={clsx("", {
            "text-blue": romero,
            "text-red": !romero,
          })}
        >
          {heading}
        </Text>
      </div>
      <div className="my-3">
        <Text variant={romero ? "body" : "body"} className="rich-text">
          {copy}
        </Text>
      </div>
      {link && link.link && (
        <div className="mt-auto">
          <Button
            to={link.link}
            rounded={romero}
            color={romero ? "gold" : "red"}
            outline={romero}
          >
            {link.text}
          </Button>
        </div>
      )}
    </div>
  );
};

CardIcon.defaultProps = {};

export default CardIcon;
