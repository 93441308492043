import React from "react";

import { Button, CbWrapper } from "@atoms";

const CbCopy = ({ button, indent, type }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Button to={button.url}>{button.text}</Button>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
