import React from "react";

import { CbWrapper, Image } from "@atoms";

const CbImageGrid = ({ imageLarge, imageSmallTop, imageSmallBottom, type }) => {
  return (
    <CbWrapper type={type}>
      <div className="flex flex-col items-start gap-4 md:flex-row md:gap-8">
        <div className="w-full shadow-xl md:w-2/3">
          <Image image={imageLarge} />
        </div>
        <div className="flex w-full flex-row items-start gap-4 md:w-1/3 md:flex-col md:gap-8">
          <div className="w-full flex-grow shadow-xl">
            <Image image={imageSmallTop} />
          </div>
          <div className="w-full flex-grow shadow-xl">
            <Image image={imageSmallBottom} />
          </div>
        </div>
      </div>
    </CbWrapper>
  );
};

CbImageGrid.defaultProps = {};

export default CbImageGrid;
