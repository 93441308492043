import dayjs from "dayjs";
import resolveImage from "./resolveImage";
import resolveImages from "./resolveImages";

const resolveCB = contentBuilder =>
  contentBuilder.map(block => {
    const { typeHandle: type } = block;
    switch (type) {
      case "heading":
        return {
          type,
          heading: block.heading,
        };
      case "subheading":
        return {
          type,
          subheading: block.subheading,
        };
      case "leadText":
        return {
          type,
          copy: block.leadText,
        };
      case "copy":
        return {
          type,
          copy: block.copy,
          indent: block.indent,
        };
      case "largeCopy":
        return {
          type,
          copy: block.copy,
        };
      case "list":
        return {
          type,
          copy: block.listItem,
          restartCount: block.restartCount,
        };
      case "image":
        return {
          type,
          images: resolveImages(block.images),
          indent: block.indent,
        };
      case "imageGrid":
        return {
          type,
          imageLarge: resolveImage(block.imageLarge),
          imageSmallTop: resolveImage(block.imageSmallTop),
          imageSmallBottom: resolveImage(block.imageSmallBottom),
        };
      case "video":
        return {
          type,
          indent: block.indent,
          videoUrl: block.videoUrl,
          caption: block.caption,
          aspectRatio: block.aspectRatio
            ? parseInt(block.aspectRatio.split("_")[0], 10) /
              parseInt(block.aspectRatio.split("_")[1], 10)
            : 16 / 9,
        };
      case "link":
        return {
          type,
          link: { link: block.linkLink.url, text: block.linkText },
          indent: block.indent,
          margin: block.removeTopMargin ? "removeTop" : "default",
        };
      case "blockQuote":
        return {
          type,
          quote: block.quote,
          citation: block.citation,
        };
      case "projects":
        return {
          type,
          projectEntries: block.projectEntries.map(entry => {
            return {
              uid: entry.uid,
              url: entry.url,
              title: entry.heading0,
              copy: entry.copy0,
            };
          }),
        };
      case "button":
        return {
          type,
          button: block.button,
        };
      case "embed":
        return {
          type,
          code: block.code,
        };
      case "resources":
        return {
          type,
          resourceEntries: block.resourceEntries.map(entry => {
            return {
              uid: entry.uid,
              url: entry.url || entry.shareUrlOverride,
              title: entry.title,
              image: resolveImage(entry.backgroundImage0),
              date: dayjs(entry.postDate).format("MMMM DD, YYYY"),
              type: entry.typeHandle,
            };
          }),
        };
      default:
        return null;
    }
  });

export default resolveCB;
