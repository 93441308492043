import gdpr from "@utils/gdpr";
import { registerMiddleware } from "../lib/middleware";

const gdprCookieName = `${process.env.GATSBY_APP_NAME}_gdpr`;

const reducer = (state, action) => {
  const { meta } = action;
  switch (action.type) {
    case "setMeta":
      return {
        ...state,
        meta,
      };
    case "startTransition":
      return {
        ...state,
        isTransitioning: true,
        transitionTo: action.to,
      };

    case "endTransition":
      return {
        ...state,
        isTransitioning: false,
        transitionTo: action.to,
      };

    case "acceptGdpr":
      return {
        ...state,
        gdpr: true,
      };

    case "hideNav":
      return {
        ...state,
        hideNav: true,
      };

    case "showNav":
      return {
        ...state,
        hideNav: false,
      };

    case "setTheme":
      return {
        ...state,
        foreground: action.foreground,
        background: action.background,
      };

    case "setThemeDark":
      return {
        ...state,
        theme: "dark",
      };

    case "setThemeLight":
      return {
        ...state,
        theme: "light",
      };

    case "setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };

    case "setPath":
      return {
        ...state,
        path: action.path,
      };

    case "setEcPage":
      return {
        ...state,
        ecPage: action.pageNum,
      };

    case "setEcLayout":
      return {
        ...state,
        ecShowSpread: action.showSpread,
      };
    case "openNav":
      return { ...state, navOpen: true };
    case "closeNav":
      return { ...state, navOpen: false };
    case "toggleNav":
      return { ...state, navOpen: !state.navOpen };
    case "modalOpen":
      return {
        ...state,
        modalOpen: true,
      };
    case "modalClosed":
      return {
        ...state,
        modalOpen: false,
      };
    case "setPageSize":
      return {
        ...state,
        pageSize: action.pageSize,
      };
    default:
      return state;
  }
};

const { hasGdprConsent, setGdprConsent } = gdpr;

registerMiddleware({
  actionType: "acceptGdpr",
  func: () => {
    setGdprConsent(gdprCookieName);
  },
});

const initialState = {
  gdpr: hasGdprConsent(gdprCookieName),
  previewMode: false,
  theme: process.env.DEFAULT_THEME || "light",
  isTransitioning: false,
  transitionTo: null,
  path: null,
  template: "action",
  hideNav: false,
  modalOpen: false,
  // education center stuff
  showSpreads: false, // toggles with mobile / desktop
  foreground: null, // text, buttons, etc
  background: null, // background
  pageSize: { height: 0, width: 0 },
  navOpen: false,
  meta: null,
};

export default {
  reducer,
  initialState,
};
