import queryCraftGQL from "../utils/queryCraftGQL";

const query = `
query MyQuery($date:String) {
  entries(section:"contribution", after:$date, site:"*") {
    ... on contribution_public_Entry {
      slug
      date: dateCreated
      goal
      fName
      fundraiser {
        title
        slug
      }
    }
    ... on contribution_anonymous_Entry {
      slug
      date: dateCreated
      goal
      fundraiser {
        title
        slug
      }
    }
  }
}
`;

const getContribtions = async date => {
  const variables = { date };
  const contributions = (await queryCraftGQL({ query, variables })).entries;
  return contributions.map(c => ({
    id: c.slug,
    date: c.date,
    amount: c.goal / 100,
    name: c.fName || "Anonymous",
    fundraiser: c.fundraiser[0]
      ? {
          title: c.fundraiser[0].title,
          slug: c.fundraiser[0].slug,
        }
      : null,
  }));
};

export default getContribtions;
