import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query RomeroThankYouVideoQuery {
    craft {
      globalSet(handle: "thankYouVideos", site: "romero") {
        ... on Craft_thankYouVideos_GlobalSet {
          thankYouVideos {
            url
            aspectRatio
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { thankYouVideos } = craft.globalSet;
  return thankYouVideos.map(video => ({
    ...video,
    aspectRatio: video.aspectRatio
      ? parseInt(video.aspectRatio.split("_")[0], 10) /
        parseInt(video.aspectRatio.split("_")[1], 10)
      : 16 / 9,
  }));
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
