import brand from "@utils/brand";

const { romero } = brand;
// template for custom frequency selection
const freqTemplate = (freq, currentFreq) => {
  const label = document.createElement("label");
  if (romero) {
    label.setAttribute(
      "class",
      freq === currentFreq
        ? "label-amount text-white bg-red"
        : "label-amount text-blue"
    );
  } else {
    label.setAttribute(
      "class",
      freq === currentFreq
        ? "label-amount text-white bg-red"
        : "label-amount text-red"
    );
  }

  label.setAttribute("title", String(freq));

  const input = document.createElement("input");
  input.setAttribute("value", freq);
  input.setAttribute("type", "radio");
  input.setAttribute("name", "CustomFrequency");
  if (freq === currentFreq) {
    input.checked = true;
  }

  const a = document.createElement("a");
  label.append(input);
  label.append(freq === 6 ? "Yearly" : "Monthly");
  label.append(a);
  return label;
};

// add the custom frequency selection
const createFrequencies = (freqs, container, currentFreq) => {
  const customFrequencies = [...freqs]
    .sort((a, b) => a - b)
    .map(freq => freqTemplate(freq, currentFreq));
  const heading = document.createElement("div");
  const atradio = document.createElement("div");
  atradio.setAttribute("class", "at-radio");
  const atradios = document.createElement("div");
  atradios.setAttribute("class", "at-radios");
  heading.setAttribute(
    "class",
    romero
      ? "font-serif text-center mb-3 text-h4 sm:text-h5 leading-tighter"
      : "font-serif font-bold text-center mb-3 text-h4 sm:text-h5 leading-tighter"
  );
  heading.append("Payments Made:");
  container.append(heading);
  customFrequencies.forEach(f => {
    atradios.append(f);
  });
  atradio.append(atradios);
  container.append(atradio);
};

// set + add the fucstom frequencies to dom
const setCustomFrequencies = currentFreq => {
  const options = Array.from(
    document.querySelectorAll(".at-recurring select option")
  );
  if (options && options.length > 2) {
    const recurringCont = document.querySelector(".at-recurring");
    document.querySelector(".IsRecurring").classList.add("hidden");
    const paymentsHeading = document.createElement("div");
    paymentsHeading.setAttribute(
      "class",
      romero
        ? "font-serif text-center mb-3 text-h4 sm:text-h5 leading-tighter"
        : "font-serif font-bold text-center mb-3 text-h4 sm:text-h5 leading-tighter"
    );
    paymentsHeading.append("Payment Method:");
    document
      .querySelector(".at-payment-method-buttons")
      .prepend(paymentsHeading);
    // document.querySelectorAll(".at-recurring select").removeChild();
    createFrequencies([4, 6], recurringCont, currentFreq);
  }
};

export default setCustomFrequencies;
