import { getAllContributions, getAllContributionsAfter } from "@liveQueries";
import brand from "@utils/brand";

const getContributions = async () => {
  if (typeof window !== "undefined") {
    const { localStorage } = window;
    const contributions =
      JSON.parse(localStorage.getItem("contributions-gt-2023-live-00")) || [];
    const latestContribution = JSON.parse(
      localStorage.getItem("latestContribution-gt-2023-live-00")
    );
    const newContributions = latestContribution
      ? getAllContributionsAfter(latestContribution)
      : getAllContributions();
    const allContributions = [...contributions, ...(await newContributions)];
    const ids = [];
    const filteredContributions = [];
    allContributions.forEach(c => {
      if (!ids.includes(c.id)) {
        ids.push(c.id);
        filteredContributions.push(c);
      }
    });
    const sortedContributions = filteredContributions.sort(
      (a, b) => Date.parse(a.date) - Date.parse(b.date)
    );
    localStorage.setItem(
      "latestContribution-gt-2023-live-00",
      JSON.stringify(sortedContributions[0].date)
    );
    localStorage.setItem(
      "contributions-gt-2023-live-00",
      JSON.stringify(sortedContributions)
    );
    return sortedContributions;
  }
  return null;
};

const reducer = (state, action) => {
  let contributions;
  switch (action.type) {
    case "updateContributions":
      // get most recent contributions
      contributions = getContributions().then(res => res);
      return {
        ...state,
        contributions,
        // mostRecent: contributions.contributions[0].date,
      };
    // case "pushContribution":
    //   // add a contribution to existing contributions
    //   contributions = [
    //     {
    //       ...action.contribution,
    //       id: action.contribution.slug,
    //       date: new Date(),
    //     },
    //     ...state.contributions,
    //   ];
    //   return {
    //     ...state,
    //     contributions,
    //     // mostRecent: contributions.contributions[0].date,
    //   };
    default:
      return state;
  }
};

const { site } = brand;

const initialState = {
  contributions: site === "lakotaGc" ? getContributions() : [],
};

export default {
  reducer,
  initialState,
};
