import { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import useTouchDevice from "@hooks/useTouchDevice";

const hasWindow = typeof window !== "undefined";

const getSize = isTouch => {
  if (hasWindow && isTouch) {
    return {
      innerHeight: hasWindow
        ? // eslint-disable-next-line no-restricted-globals
          // screen?.height ||
          document.documentElement.clientHeight || window.innerHeight
        : 0,
      innerWidth: hasWindow
        ? // eslint-disable-next-line no-restricted-globals
          // screen?.width ||
          document.documentElement.clientWidth || window.innerWidth
        : 0,
      outerHeight: hasWindow ? window.outerHeight : 0,
      outerWidth: hasWindow ? window.outerWidth : 0,
    };
  }
  return {
    innerHeight: hasWindow ? window.innerHeight : 0,
    innerWidth: hasWindow ? window.innerWidth : 0,
    outerHeight: hasWindow ? window.outerHeight : 0,
    outerWidth: hasWindow ? window.outerWidth : 0,
  };
};

const useWindowSize = () => {
  const ticking = useRef();
  const [windowSize, setWindowSize] = useState(getSize());
  const isTouch = useTouchDevice();

  const handleResize = () => {
    if (!hasWindow) {
      return false;
    }

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        setWindowSize(getSize(isTouch));
        ticking.current = false;
      });

      ticking.current = true;
    }

    return true;
  };

  const dHandleResize = debounce(handleResize, 200, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    dHandleResize();
    window.addEventListener("resize", dHandleResize);

    return () => window.removeEventListener("resize", dHandleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
