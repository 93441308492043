import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { CbWrapper, Icon } from "@atoms";
import { AppLink } from "@base";

const CbCopy = ({ link, indent, margin, type }) => {
  const { lakota, lgca, romero } = brand;
  return (
    <CbWrapper type={type} indent={indent} margin={margin}>
      <AppLink
        to={link.link}
        className={clsx("group inline-flex items-center", {
          "font-sans": lakota,
          "font-extended": lgca,
          "text-black hover:text-gold": romero,
        })}
      >
        {/* flame icon */}
        {romero && (
          <span className="romeroicon flex-shink-0 mr-2 h-3 w-3 transform text-gold">
            <Icon name="flame" />
          </span>
        )}
        {/* label */}
        <span
          className={clsx("leading-normal", {
            "underline-red text-xs font-bold uppercase": !romero,
            "text-md underline-gold font-medium": romero,
          })}
        >
          {link.text}
        </span>
      </AppLink>
    </CbWrapper>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
