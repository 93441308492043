import createAppState from "./lib/createAppState";

import layout from "./reducers/layout";
import ea from "./reducers/ea";
import ec from "./reducers/ec";
import p2p from "./reducers/p2p";
import contributions from "./reducers/contributions";
import modal from "./reducers/modal";

const reducer = {
  layout: layout.reducer,
  ea: ea.reducer,
  ec: ec.reducer,
  p2p: p2p.reducer,
  contributions: contributions.reducer,
  modal: modal.reducer,
};

const initialState = {
  layout: layout.initialState,
  ea: ea.initialState,
  ec: ec.initialState,
  p2p: p2p.initialState,
  contributions: contributions.initialState,
  modal: modal.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
