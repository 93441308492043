// this queries craft

const queryCraft = async ({ query, variables }) => {
  try {
    const result = await fetch(process.env.GATSBY_CRAFT_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }).then(res => res.json());
    return result.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return false;
  }
};

export default queryCraft;
