const reducer = (state, action) => {
  switch (action.type) {
    case "setKey":
      return {
        ...state,
        key: action.key,
      };
    default:
      return state;
  }
};

const initialState = {
  key: null,
};

export default {
  reducer,
  initialState,
};
