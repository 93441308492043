import React from "react";
// eslint-disable-next-line import/no-cycle
import { Share, DonationFeedBanner } from "@molecules";
import { Twitch, Text, Button, ButtonAlt } from "@atoms";

const Stream = ({
  channel,
  heading,
  copy,
  button,
  links,
  videoPlaceholder,
  historySection,
  url,
}) => {
  return (
    <>
      <div className="relative w-full" style={{ paddingBottom: "56.25%" }}>
        <Twitch
          videoPlaceholder={videoPlaceholder}
          channel={channel}
          layout="video"
          muted
          width="100%"
          height="100%"
        />
      </div>
      <div>
        <DonationFeedBanner />
      </div>
      {/* stream details */}
      <div className="relative flex-grow bg-white p-6">
        <Text variant="h4">{heading}</Text>
        <Text variant="body" className="mt-4">
          {copy}
        </Text>
        <div className="mt-6 flex w-full flex-col flex-wrap items-start space-y-3 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-6">
          <Button to={button.url} size="sm">
            {button.text}
          </Button>
          {links?.map((link, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <ButtonAlt key={i} to={link.url}>
                {link.text}
              </ButtonAlt>
            );
          })}
          <ButtonAlt
            onClick={() => {
              historySection.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            About Us
          </ButtonAlt>
        </div>
        <Share compact small dark url={url} />
      </div>
    </>
  );
};

Stream.defaultProps = {};

export default Stream;
