import React from "react";

import { Text, Image, Icon, ButtonAlt, Container } from "@atoms";
import brand from "@utils/brand";
import clsx from "clsx";
import { useAppState } from "@state";

const PersonModal = ({ name, title, bio, image }) => {
  const { lgca, lakota, romero } = brand;
  const [, dispatch] = useAppState();

  const openModal = () => {
    dispatch({
      type: "openModal",
      background: "bg-black",
      // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
      content: React.memo(() => (
        <div
          className={clsx(
            "relative flex min-h-full w-full flex-grow items-center justify-center p-6 sm:p-12",
            {
              "bg-cream": lakota,
              "bg-white": lgca,
              "bg-near-white": romero,
            }
          )}
        >
          <div className="absolute right-0 top-0 p-3">
            <button
              type="button"
              onClick={() => {
                dispatch({ type: "closeModal" });
              }}
            >
              <Icon
                name="close"
                className={clsx("h-8 w-8", {
                  "font-sans text-red": lakota,
                  "font-extended text-blue": lgca,
                  "tracking-widest text-gold": romero,
                })}
              />
            </button>
          </div>
          <div className="my-auto w-full">
            <Container>
              <div className="flex w-full flex-wrap items-start justify-center">
                <div className="order-2 -mt-px flex w-full flex-col items-start justify-start px-3 lg:w-3/5">
                  <Text
                    variant="label"
                    className={clsx("-mt-px", {
                      "font-extended text-orange": lgca,
                    })}
                  >
                    {title}
                  </Text>
                  <Text
                    variant={romero ? "h4" : "body--2xl-tight"}
                    className={clsx("my-2", {
                      "font-serif text-blue": lgca,
                      "font-bold": !romero,
                    })}
                  >
                    {name}
                  </Text>
                  <Text
                    variant={romero ? "body" : "body--large-tight"}
                    className="max-w-md"
                  >
                    {bio}
                  </Text>
                </div>
                <div className="order-1 flex w-full flex-col justify-end px-3 lg:w-2/5">
                  {image && (
                    <div className="relative mb-3 w-full max-w-xs">
                      <Image
                        image={image}
                        ixParams={{ crop: "faces" }}
                        aspectRatio={[1, 1]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>
      )),
    });
  };

  return (
    <button
      type="button"
      onClick={openModal}
      className="flex w-full flex-col items-start justify-start text-left"
    >
      {image && (
        <span className="mx-auto mb-3 block w-full">
          <Image
            image={image}
            ixParams={{ crop: "faces", fit: "crop" }}
            aspectRatio={[1, 1]}
          />
        </span>
      )}
      <Text
        variant={romero ? "label" : "body--small-tight"}
        className={clsx("uppercase", {
          "font-sans font-bold text-red": lakota,
          "font-extended font-bold text-blue": lgca,
          "tracking-widest text-gold": romero,
        })}
      >
        {title}
      </Text>
      <Text
        variant={romero ? "h4" : "body--xl-tight"}
        className={clsx("my-2", {
          "font-bold": !romero,
        })}
      >
        {name}
      </Text>
      {romero && <ButtonAlt color="gold">Read Profile</ButtonAlt>}
      {!romero && (
        <ButtonAlt color={lgca ? "blue" : "red"} noIcon>
          View Profile
        </ButtonAlt>
      )}
    </button>
  );
};

PersonModal.defaultProps = {};

export default PersonModal;
