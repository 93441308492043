// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

export const getIsTouch = () => {
  if (
    "ontouchstart" in window ||
    // eslint-disable-next-line no-undef
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    ""
  );
  return window.matchMedia(query).matches;
};

const useTouchDevice = () => {
  const [isTouch, setIsTouch] = useState(true);
  useEffect(() => {
    setIsTouch(getIsTouch());
  }, []);

  return isTouch;
};

export default useTouchDevice;
