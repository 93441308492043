import React, { useEffect, useState, useRef } from "react";

import { Text, AnimateIn, Icon } from "@atoms";
import useWindowSize from "@hooks/useWindowSize";
import _brand from "@utils/brand";
import clsx from "clsx";

const StatCard = ({ stat, heading, description, color, colors }) => {
  const [textTooBig, setTextTooBig] = useState();
  const { innerWidth: windowSize } = useWindowSize();
  const statRef = useRef();
  const statSubRef = useRef();
  const statContainerRef = useRef();
  const { lakota, lgca, brand, romero } = _brand;
  useEffect(() => {
    if (statRef.current && statSubRef.current && statContainerRef.current) {
      setTextTooBig(
        statRef.current.clientWidth + statSubRef.current.clientWidth >
          statContainerRef.current.clientWidth
      );
    }
  }, [windowSize]);
  return (
    <div
      className={clsx(
        `${colors[brand][color].background} p-3 shadow-lg lg:p-6`,
        { "text-center": lgca }
      )}
      style={{ fontcariantNumeric: "tabular-nums" }}
    >
      <AnimateIn>
        <div ref={statContainerRef} className="w-full">
          <div
            className={clsx(
              `flex items-end ${textTooBig || lgca ? "flex-wrap" : ""}`,
              { "justify-start": lakota, "mt-6 justify-center": lgca }
            )}
          >
            <div
              ref={statRef}
              className={clsx(" items-end", {
                "inline-flex": lakota,
                "w-full": lgca,
              })}
            >
              <Text
                variant="stat"
                className={`${colors[brand][color].heading} font-bold sm:-mt-1 md:-mt-2 lg:-mt-4`}
              >
                {stat}
              </Text>
            </div>
            <div
              ref={statSubRef}
              className={
                (clsx("flex-col lg:mb-1"),
                { "-mt-6 flex w-full": lgca, "inline-flex": lakota })
              }
            >
              <Text
                variant="statSub"
                className={clsx(
                  "mb-2 mt-auto py-1 pl-1 font-bold lg:py-3 lg:pl-2",
                  colors[brand][color].heading,
                  {
                    "font-extended": lgca,
                    "font-serif": lakota,
                    "font-sans": romero,
                  }
                )}
              >
                {heading}
              </Text>
            </div>
          </div>
        </div>
        <Text
          className={clsx(
            "block max-w-md px-3 text-lg font-bold leading-tight text-white",
            colors[brand][color].copy,
            { "font-extended": lgca, "font-serif": lakota, "font-sans": romero }
          )}
        >
          {description}
        </Text>
        {lgca && (
          <div className="relative mb-3 mt-3 hidden items-center justify-center md:flex">
            <Icon
              name="lgcaSunOutline"
              className="relative z-10 h-6 w-6 text-white"
            />
            <div
              className={`${colors[brand][color].heading} absolute inset-0 z-0 scale-1/2 transform`}
            >
              <svg
                className="h-full w-full fill-current"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50" />
              </svg>
            </div>
          </div>
        )}
      </AnimateIn>
    </div>
  );
};

StatCard.defaultProps = {
  color: 0,
};

export default StatCard;
