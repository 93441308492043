import React from "react";

import { Text, CbWrapper, ButtonAlt } from "@atoms";

const CbProjects = ({ projectEntries, type }) => {
  return (
    <CbWrapper type={type}>
      {projectEntries.map(p => (
        <div key={p.uid} className="my-10 border-l-3 border-gold pl-4">
          <Text variant="h5">{p.title}</Text>
          <Text variant="body" className="my-3">
            {p.copy}
          </Text>
          <ButtonAlt to={p.url} color="gold">
            Learn More
          </ButtonAlt>
        </div>
      ))}
    </CbWrapper>
  );
};

CbProjects.defaultProps = {};

export default CbProjects;
