import React from "react";

import { Icon, Text } from "@atoms";

const LgcaCaption = ({ heading, descriptor }) => {
  return (
    <div className="flex items-start justify-start">
      <div className="flex flex-shrink-0 flex-col items-start justify-start pr-1">
        <Icon name="lgcaSunOutline" className="-mt-1 h-6 w-6 text-blue" />
      </div>
      <div>
        <div className="mb-2 text-sm">
          <div className="font-extended uppercase leading-tight tracking-1 text-blue text-white">
            {heading}
          </div>
        </div>
        <div className="max-w-xs">
          <Text className="font-sans text-base font-bold leading-tight text-white">
            {descriptor}
          </Text>
        </div>
      </div>
    </div>
  );
};

LgcaCaption.defaultProps = {};

export default LgcaCaption;
