import React from "react";

import clsx from "clsx";
import { AppLink } from "@base";
import { Icon, Image, Text, ButtonAlt } from "@atoms";
import brand from "@utils/brand";

const PressTease = d => {
  const { romero } = brand;
  const { image, url, date, title } = d;
  return (
    <AppLink to={url} className="group flex w-full text-left">
      {!image && (
        <div
          className={clsx(
            "mr-4 flex w-16 flex-shrink-0 items-center justify-center sm:w-24 md:w-32",
            {
              "text-red": !romero,
              "text-blue": romero,
            }
          )}
        >
          <Icon name="document" className="h-12 w-12" />
        </div>
      )}
      {image && (
        <div
          className={clsx("mr-4 w-16 flex-shrink-0 sm:w-24 md:w-32", {
            "text-red": !romero,
          })}
        >
          <Image
            image={image}
            ixParams={romero ? { crop: "faces", fit: "crop" } : {}}
            aspectRatio={romero ? [1, 1] : null}
          />
        </div>
      )}
      <div className="w-full">
        {romero && <Text variant="label">{date}</Text>}
        <Text
          variant={romero ? "h6" : "body--large-tight"}
          className={clsx({
            "font-bold": !romero,
            "mt-2": romero,
          })}
        >
          {title}
        </Text>
        {romero && (
          <ButtonAlt color="gold" className="mt-4">
            Read Article
          </ButtonAlt>
        )}
      </div>
    </AppLink>
  );
};

PressTease.defaultProps = {};

export default PressTease;
