import React, { useRef, useEffect, useState } from "react";

import clsx from "clsx";
import { Image } from "@atoms";

const Slideshow = ({ slides, interval, ...rest }) => {
  // ref helps prevent mutations to the timing interval
  const autoplay = useRef(null);

  // function to reset the interval
  const resetAutoplay = () => {
    if (autoplay.current) {
      clearInterval(autoplay.current);
    }
  };
  // set up states
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // remove the exiting autoplay interval if there is one
    resetAutoplay();

    // set up the autoplay
    if (interval) {
      autoplay.current = setInterval(() => {
        setCurrentSlide(prevSlide =>
          prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
      }, interval);
    }

    // gc
    return () => {
      resetAutoplay();
    };
    // trigger this function again if the interval changes
  }, [interval]);

  // check to make sure there is an array of images
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <div className="absolute inset-0 z-0">
      {slides.map((s, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={clsx("absolute inset-0 z-0 transition duration-1000", {
            "opacity-100": i === currentSlide,
            "opacity-0": i !== currentSlide,
          })}
        >
          <Image key={s.title} image={s} {...rest} />
        </div>
      ))}
    </div>
  );
};

Slideshow.defaultProps = {
  interval: 5000,
};

export default Slideshow;
