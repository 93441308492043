const selectAmount = (amount, isIdx) => {
  const amountEls = Array.from(
    document.querySelectorAll(".SelectAmount .label-amount")
  );
  if (amountEls.length) {
    if (isIdx && amount !== -1) {
      amountEls[amount].click();
    } else if (!isIdx) {
      try {
        // click the relevant element
        amountEls
          .find(el => {
            const amt = String(parseFloat(amount).toFixed(2));
            const elAmt = String(
              parseFloat(
                el.getAttribute("data-amount") ||
                  el.getAttribute("title").replace("$", "")
              ).toFixed(2)
            );

            return amt === elAmt;
          })
          .click();
      } catch (err) {
        // otherwise fill out the other field
        try {
          const otherInput = document.querySelector(
            "input[name='OtherAmount']"
          );
          otherInput.click();
          otherInput.focus();
          otherInput.value = parseFloat(amount);
          otherInput.blur();
        } catch (_err) {
          // eslint-disable-next-line no-console
          console.log(_err);
        }
      }
    }
  }
};

export default selectAmount;
