import React, { useState, useEffect, useRef } from "react";
import { useAppState } from "@state";
import { m } from "framer-motion";
// eslint-disable-next-line import/no-cycle
import { Icon, Text, Container, BigLink, Button } from "@atoms";
import { AppLink } from "@base";
import clsx from "clsx";
import brand from "@utils/brand";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const SiteNav = ({ data }) => {
  const {
    heading,
    copy,
    campaigns,
    primaryNav,
    secondaryNav,
    campaignsHeading,
    button,
  } = data;

  const [{ layout }, dispatch] = useAppState();
  const { theme, navOpen: open } = layout;
  const { lgca, lakota, romero } = brand;

  // the component itself
  const [prevTheme, setPrevTheme] = useState(theme);

  const navEl = useRef();

  useEffect(() => {
    if (!open) {
      setPrevTheme(theme);
    }
  }, [theme]);

  // whethere there are any activist codes
  useEffect(() => {
    if (open) {
      disableBodyScroll(navEl.current);
      if (prevTheme === "light") {
        dispatch({ type: "setThemeDark" });
      }
    } else {
      enableBodyScroll(navEl.current);
      if (prevTheme === "light") {
        dispatch({ type: "setThemeLight" });
      }
    }
  }, [open]);

  const variants = {
    visible: {
      display: "block",
      // y: 0,
      opacity: 1,
    },
    hidden: {
      display: "hidden",
      // y: -100,
      opacity: 0,
    },
  };

  return (
    <m.div
      variants={variants}
      ref={navEl}
      animate={open ? "visible" : "hidden"}
      className={clsx(
        "absolute top-0 z-[90] hidden h-screen w-screen overflow-auto text-white sm:pb-24",
        {
          "z-40": !lgca,
          "z-70 bg-orange/95 pt-6 sm:z-40 sm:pt-48": lgca,
          "bg-black/95 pt-6 sm:pt-48": lakota,
          "bg-blue pt-6 md:pt-48": romero,
          "pointer-events-none": !open,
        }
      )}
    >
      <Container>
        <div className="relative">
          <div className="-mx-4 flex flex-wrap pr-8 sm:pr-0 lg:-mx-12">
            <div
              className={clsx(
                "SiteNav--animate absolute right-0 top-0 flex h-8 w-8 items-center justify-center",
                {
                  "text-red": !romero,
                  "text-gold": romero,
                }
              )}
            >
              <button
                type="button"
                onClick={() => {
                  dispatch({ type: "closeNav" });
                }}
                className="cursor-pointer"
              >
                <Icon name="close" className="h-6 w-6" />
              </button>
            </div>
            <div className="w-full flex-shrink-0 px-6 md:w-1/2 lg:px-12">
              <div className="SiteNav--animate">
                <Text
                  variant="h3"
                  className={clsx("", { "-mt-1 text-blue": lgca })}
                  richText={false}
                >
                  {heading}
                </Text>
                {copy && (
                  <Text
                    variant={romero ? "body--xl" : "body--xl-tight"}
                    className="mt-3"
                  >
                    {copy}
                  </Text>
                )}
              </div>
              {primaryNav && (
                <div className="SiteNav--animate mt-12 hidden uppercase sm:block">
                  {primaryNav.map(link => {
                    return (
                      <div key={link.uid || link.link} className="mb-4">
                        <AppLink
                          to={link.link}
                          className={clsx(
                            "pb-2 uppercase text-white transition",
                            {
                              "underline-hover font-bold duration-200": !romero,
                              "inline-block font-medium tracking-widest duration-200 hover:text-gold":
                                romero,
                            }
                          )}
                          activeClassName={clsx({
                            "underline-red": !romero,
                            "text-white": romero,
                          })}
                        >
                          {link.text}
                        </AppLink>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="SiteNav--animate mt-12 hidden text-sm sm:block">
                {/* {romero && <Text variant="xs">Programs</Text>} */}
                {secondaryNav &&
                  secondaryNav.map(link => {
                    return (
                      <div key={link.uid || link.link} className="mb-6">
                        <AppLink
                          to={link.link}
                          className={clsx(
                            "uppercase transition hover:text-white",
                            {
                              "font-bold tracking-1 text-red duration-200":
                                !romero,
                              "font-medium tracking-widest text-gold duration-200":
                                romero,
                            }
                          )}
                        >
                          {link.text}
                        </AppLink>
                      </div>
                    );
                  })}
              </div>
            </div>
            {button?.url && (
              <div className="my-4 block w-full flex-shrink-0 px-6 sm:mb-0 sm:hidden sm:w-1/2 lg:px-12">
                <Button
                  size="sm"
                  to={button.url}
                  rounded={romero}
                  color={romero ? "gold" : "red"}
                  outline={romero}
                  icon={romero}
                >
                  {button.text}
                </Button>
              </div>
            )}
            <div className="my-4 w-full flex-shrink-0 px-6 sm:mb-0 sm:w-1/2 lg:px-12">
              <div
                className={clsx(
                  "SiteNav--animate mb-6 text-xs uppercase tracking-1 sm:mb-8",
                  {
                    "font-bold text-red": !romero,
                    "font-medium text-gold": romero,
                  }
                )}
              >
                {campaignsHeading || "campaigns"}
              </div>
              <ul>
                {campaigns?.map(campaign => (
                  <li key={campaign.uid || campaign.url}>
                    <BigLink
                      className="SiteNav--animate mb-6 sm:mb-8"
                      to={campaign.url}
                    >
                      {campaign.heading}
                    </BigLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="SiteNav--animate w-full flex-shrink-0 px-6 text-sm tracking-1 sm:hidden sm:w-1/2 lg:px-12">
              {primaryNav?.map(link => {
                return (
                  <div key={link.uid || link.link} className="mb-4">
                    <AppLink
                      to={link.link}
                      className={clsx("pb-2 uppercase text-white transition", {
                        "underline-hover font-bold duration-200": !romero,
                        "inline-block font-medium duration-200 hover:text-gold":
                          romero,
                      })}
                      activeClassName={romero ? "" : "underline-red"}
                    >
                      {link.text}
                    </AppLink>
                  </div>
                );
              })}
              {secondaryNav &&
                secondaryNav.map(link => {
                  return (
                    <div key={link.uid || link.link} className="mb-6">
                      <AppLink
                        to={link.link}
                        className={clsx(
                          "uppercase transition hover:text-white",
                          {
                            "font-bold text-red duration-200": !romero,
                            "font-medium text-gold duration-200": romero,
                          }
                        )}
                      >
                        {link.text}
                      </AppLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
      <div className="h-20 sm:hidden" />
    </m.div>
  );
};

SiteNav.defaultProps = {
  open: false,
};

export default SiteNav;
