import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Image, Text } from "@atoms";

const Testimonial = ({ image, quote, citation }) => {
  const { romero } = brand;
  return (
    <div className="p-3">
      <div className="-mx-6 flex flex-wrap items-center sm:flex-nowrap">
        <div className="w-full flex-shrink-0 px-6 sm:w-auto">
          {image && (
            <div className="mx-auto mb-3 h-48 w-48 overflow-hidden rounded-full md:h-56 md:w-56 lg:h-64 lg:w-64">
              <Image
                image={image}
                ixParams={{ fit: "facearea", facepad: 10 }}
                aspectRatio={[1, 1]}
                round
              />
            </div>
          )}
        </div>
        <div className="my-6 w-full px-6 sm:w-auto">
          {quote && (
            <Text
              variant="body--2xl-tight"
              className={clsx("font-serif mb-3", {
                "font-bold": !romero,
              })}
              quotes={!romero}
            >
              {quote}
            </Text>
          )}
          {citation && (
            <Text variant="body--large-tight" className="citation mt-4">
              {citation}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Testimonial.defaultProps = {
  inverse: false,
};

export default Testimonial;
