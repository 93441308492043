import React, { useRef } from "react";

import useDynamicSVGImport from "@utils/useDynamicSVGImport";

// eslint-disable-next-line react/display-name
const Icon = ({
  name,
  className: _className = "w-16 h-16",
  encode,
  fitHeight,
}) => {
  const icon = useRef();
  const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
  return (
    <span
      ref={icon}
      className={`romeroicon${
        fitHeight ? "--fit-height" : ""
      } block fill-current ${_className}`}
    >
      {!encode && !loading && SvgIcon && <SvgIcon />}
      {encode && !loading && SvgIcon && (
        <img alt={name} className="block h-full w-full" src={SvgIcon} />
      )}
    </span>
  );
};

export default Icon;
