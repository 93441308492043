// generic functions
const removeParam = (parameter, url) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split("?");
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    // eslint-disable-next-line no-plusplus
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    const newUrl = `${urlparts[0]}?${pars.join("&")}`;
    return newUrl;
  }
  return url;
};

const removeUrlParam = param => {
  window.history.replaceState(
    {},
    document.title,
    removeParam(param, window.location.href)
  );
};

export default removeUrlParam;
