import React from "react";

import clsx from "clsx";
import brand from "@utils/brand";
import { Text, Button, Icon } from "@atoms";

const LevelCardSmall = ({
  icon,
  heading,
  subheading,
  benefits,
  benefitsTitle,
  copy,
  link,
  footnote,
}) => {
  const { romero } = brand;

  return (
    <div className="relative mx-auto h-full max-w-xl bg-white shadow-xl">
      <div className="relative z-10 flex h-full flex-col items-center justify-center p-4 text-center md:p-6">
        <div>
          <Icon
            name={`${icon}Membership`}
            className={clsx("h-12 w-12", {
              "text-red": !romero,
              "text-blue": romero,
            })}
            fitHeight
          />
        </div>
        <div className="my-1">
          <Text
            variant="h5"
            className={clsx({
              "text-red": !romero,
              "text-blue": romero,
            })}
          >
            {heading}
          </Text>
        </div>
        <div className="my-1">
          <Text
            className={clsx("rich-text font-sans", {
              "font-bold": !romero,
              "font-medium": romero,
            })}
          >
            {subheading}
          </Text>
        </div>
        <div className="my-3">
          <Text variant="body--large-tight" className="rich-text">
            {copy}
          </Text>
        </div>
        <Text
          variant={romero ? "body--small-tight" : "body"}
          className={clsx("mt-3 w-full text-left", {
            "font-bold text-red": !romero,
            "font-medium text-blue": romero,
          })}
        >
          {benefitsTitle}
        </Text>
        {benefits && (
          <ul
            className={clsx("my-3 w-full", {
              "list-outside list-disc pl-8": romero,
            })}
          >
            {benefits?.map((b, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className="mb-3 flex w-full">
                  {!romero && (
                    <Icon
                      className={clsx("h-4 w-4 flex-shrink-0", {
                        "mx-3 text-red": !romero,
                        "mx-2 -rotate-90 transform text-blue": romero,
                      })}
                      name={romero ? "chevron" : "arrow"}
                    />
                  )}
                  <Text
                    variant={romero ? "body--small-tight" : "body"}
                    className={clsx("rich-text w-full text-left", {
                      "list-item": romero,
                    })}
                  >
                    {b}
                  </Text>
                </li>
              );
            })}
          </ul>
        )}
        {footnote && (
          <Text variant="body--small-tight" className="mb-4 w-full text-center">
            {footnote}
          </Text>
        )}
        {link && link.link && (
          <div className="mt-auto">
            <Button
              to={link.link}
              rounded={romero}
              color={romero ? "gold" : "red"}
              outline={romero}
            >
              {link.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

LevelCardSmall.defaultProps = {};

export default LevelCardSmall;
