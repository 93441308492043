const site = process.env.GATSBY_CRAFT_SITE_HANDLE;
// get first word of camelcase
const _brand = site.replace(/([A-Z])/g, "-$1").split("-")[0];

const brand = {
  lakota: site.includes("lakota"),
  greenpower: site.includes("greenpower"),
  lgca: site.includes("lgca"),
  romero: site.includes("romero"),
  brand: _brand,
  site,
};

export default brand;
