import React from "react";

import brand from "@utils/brand";
import { Text, CbWrapper } from "@atoms";

const CbLeadText = ({ copy, indent, type }) => {
  const { romero } = brand;
  return (
    <CbWrapper type={type} indent={indent}>
      <Text variant={romero ? "body--xl" : "body--xl-tight"}>{copy}</Text>
    </CbWrapper>
  );
};

CbLeadText.defaultProps = {};

export default CbLeadText;
