import React, { useRef, useState, useMemo, useEffect } from "react";
import useTouchDevice from "@hooks/useTouchDevice";
import useWindowSize from "@hooks/useWindowSize";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import { useAppState } from "@state";
import Bubble from "@molecules/Bubble";
import debounce from "lodash.debounce";

import clsx from "clsx";
import { AnimatePresence, m } from "framer-motion";

const CarouselFullPage = ({ initialSlide, children }) => {
  const [{ ec }, dispatch] = useAppState();
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;

  const wheelListener = useRef(null);
  const keyListener = useRef(null);
  const scrollRef = useRef(null);
  const isTouch = useTouchDevice();
  const scroll = useRef(null);
  const bubbleTimeout = useRef(null);
  const [, setPeak] = useState(999);

  const { innerWidth } = useWindowSize();

  const [loaded, setLoaded] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  useEffect(() => {
    setShowBubble(false);
    if (bubbleTimeout.current) {
      clearTimeout(bubbleTimeout.current);
    }
    if (currentSlide !== 0) {
      bubbleTimeout.current = setTimeout(() => {
        setShowBubble(true);
      }, 10000);
    } else {
      setShowBubble(true);
    }
  }, [currentSlide]);

  useEffect(() => {
    dispatch({ type: "setSlide", slide: currentSlide });
    return () => {
      dispatch({ type: "exitEc" });
    };
  }, [currentSlide]);

  const { slide } = ec;

  useEffect(() => {
    if (loaded && slide !== currentSlide) {
      setCurrentSlide(slide);
    }
  }, [slide]);

  const scrollPage = useMemo(() => {
    return debounce(
      (dir, vel) => {
        setPeak(vel);
        if (scroll.current) {
          // let el = null;
          if (typeof dir === "string") {
            if (dir === "left") {
              setCurrentSlide(s => (s < slideCount - 1 ? s + 1 : s));
            }
            if (dir === "right") {
              setCurrentSlide(s => (s > 0 ? s - 1 : s));
            }
          } else {
            setCurrentSlide(s => s);
          }
        }
      },
      isTouch ? 0 : 300,
      { leading: true, trailing: false }
    );
  }, [scroll.current, loaded, isTouch]);

  useEffect(() => {
    if (wheelListener.current) {
      scrollRef.current.removeEventListener("wheel", wheelListener.current);
    }
    if (isTouch) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      disableBodyScroll(scrollRef.current);
    } else {
      enableBodyScroll(scrollRef.current);
      wheelListener.current = scrollRef.current.addEventListener("wheel", e => {
        e.preventDefault();
        setPeak(p => {
          const { deltaX: x, deltaY: y } = e;
          const [absX, absY] = [Math.abs(x), Math.abs(y)];
          // scroll left
          const vel = absY > absX ? absY : absX;
          if (Math.abs(vel) > Math.abs(p)) {
            if (
              (Math.abs(y) < Math.abs(x) && x > 0) ||
              (Math.abs(y) > Math.abs(x) && y > 0)
            ) {
              scrollPage("left");
            }
            // scroll right
            if (
              (Math.abs(y) < Math.abs(x) && x < 0) ||
              (Math.abs(y) > Math.abs(x) && y < 0)
            ) {
              scrollPage("right");
            }
          }
          return vel;
        });
      });
    }

    if (!loaded) setLoaded(true);

    return () => {
      if (wheelListener.current) {
        scrollRef.current.removeEventListener("wheel", wheelListener.current);
      }
      if (isTouch) {
        enableBodyScroll(scrollRef.current);
      }
      document.removeEventListener("keydown", keyListener.current);
    };
  }, [isTouch, innerWidth]);

  const checkKey = useMemo(
    () => _e => {
      const { keyCode } = _e || window.event;
      // up arrow
      if (String(keyCode) === "38") {
        scrollPage("right");
        // down arrow
      } else if (String(keyCode) === "40") {
        scrollPage("left");

        // left arrow
      } else if (String(keyCode) === "37") {
        scrollPage("right");

        // right arrow
      } else if (String(keyCode) === "39") {
        scrollPage("left");
      }
    },
    []
  );

  useEffect(() => {
    if (!isTouch && keyListener.current) {
      document.removeEventListener("keydown", keyListener.current);
      keyListener.current = undefined;
    }
    const isCTA = slides[currentSlide].props.thisPage.type === "lgcaCta";
    if (!isTouch && !isCTA) {
      keyListener.current = checkKey;
      document.addEventListener("keydown", keyListener.current);
    }
    return () => {
      if (!isTouch && keyListener.current) {
        document.removeEventListener("keydown", keyListener.current);
      }
    };
  }, [isTouch, currentSlide]);

  const handleDragEnd = (event, info) => {
    const {
      velocity: { x, y },
    } = info;
    if (
      (Math.abs(y) <= Math.abs(x) && x < 0) ||
      (Math.abs(y) > Math.abs(x) && y < 0)
    ) {
      scrollPage("left");
    }
    // scroll right
    else if (
      (Math.abs(y) <= Math.abs(x) && x > 0) ||
      (Math.abs(y) > Math.abs(x) && y > 0)
    ) {
      scrollPage("right");
    } else {
      scrollPage();
    }
  };

  return (
    <m.div
      ref={scrollRef}
      className={clsx(
        "ec-container absolute left-0 right-0 top-0 z-0 h-full flex-grow overflow-hidden",
        {
          "overflow-auto": !isTouch,
          "overflow-hidden": isTouch,
        }
      )}
    >
      <m.div
        ref={scroll}
        drag="x"
        initial={{ opacity: 0, x: -currentSlide * innerWidth }}
        className="flex h-full"
        dragConstraints={{ right: 0, left: -(slideCount - 1) * innerWidth }}
        dragElastic={false}
        animate={{ x: -currentSlide * innerWidth, opacity: 1 }}
        onDragEnd={handleDragEnd}
        style={{
          width: `${slideCount * 100}%`,
        }}
      >
        {slides &&
          slides.map(s => (
            <div
              key={s.props.thisPage.uid}
              className={clsx("relative flex h-full w-screen flex-shrink-0")}
            >
              {s}
            </div>
          ))}
      </m.div>
      <AnimatePresence initial>
        {showBubble && (
          <m.div
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            className={clsx("absolute right-4 z-70 transform", {
              "bottom-14 xxs:bottom-12 sm:bottom-20": !isTouch,
              "bottom-0": isTouch,
            })}
          >
            <div className="relative rounded-full bg-red">
              {!isTouch && (
                <button
                  type="button"
                  onClick={() => {
                    scrollPage("right");
                  }}
                >
                  <Bubble
                    text="Scroll"
                    showArrow
                    isHomePage
                    color={ec?.colors?.accent || "bg-red"}
                  />
                </button>
              )}
              {isTouch && (
                <button
                  type="button"
                  onClick={() => {
                    scrollPage("right");
                  }}
                >
                  <Bubble
                    text="Swipe"
                    showArrow
                    isHomePage
                    color={ec?.colors?.accent || "bg-red"}
                  />
                </button>
              )}
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </m.div>
  );
};

CarouselFullPage.defaultProps = {
  initialSlide: 0,
};

export default CarouselFullPage;
