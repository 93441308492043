const reducer = (state, action) => {
  const { slide } = action;
  let newState;

  switch (action.type) {
    case "setThemeDark":
      return { ...state, logoColor: "light", menuColor: "light" };
    case "setThemeLight":
      return { ...state, logoColor: "dark", menuColor: "dark" };
    case "ecNext":
      newState = {
        ...state,
        dir: "next",
        dest: state.nav.next,
      };
      return newState;
    case "ecPrev":
      newState = {
        ...state,
        dir: "prev",
        dest: state.nav.prev,
      };
      return newState;
    case "setEcNav":
      newState = {
        ...state,
        nav: action.nav,
      };
      return newState;
    case "setEcColors":
      newState = {
        ...state,
        // merge codes and remove duplicates
        colors: action.colors,
      };
      return newState;
    case "setEcChapters":
      newState = {
        ...state,
        // merge codes and remove duplicates
        chapters: action.chapters,
        totalPages: action.totalPages,
      };
      return newState;
    case "setHasRotated":
      newState = {
        ...state,
        hasRotated: action.value,
      };
      return newState;
    case "setHeaderColors":
      newState = {
        ...state,
        logoColor: action.logoColor,
        menuColor: action.menuColor,
      };
      return newState;
    case "setSlide":
      return { ...state, slide };
    default:
      return state;
  }
};

const initialState = {
  chapters: [],
  totalPages: 0,
  colors: {
    primary: null,
    secondary: null,
    accent: null,
  },
  logoColor: "light",
  menuColor: "dark",
  nav: {
    next: null,
    prev: null,
    chapter: 0,
    pageNum: 0,
    spread: null,
  },
  dir: null,
  dest: null,
  slide: 0,
  hasRotated: false,
};

export default {
  reducer,
  initialState,
};
