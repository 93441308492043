import React from "react";

import { Text, CbWrapper } from "@atoms";

const CbHeading = ({ heading, indent, type, inverse }) => {
  return (
    <CbWrapper type={type} indent={indent}>
      <Text inverse={inverse} variant="h3">
        {heading}
      </Text>
    </CbWrapper>
  );
};

CbHeading.defaultProps = {};

export default CbHeading;
