import { buildUrl, defaultParams } from "@utils/imageUtils";

const resolveImage = _image => {
  const image = Array.isArray(_image) ? _image[0] : _image;
  if (image) {
    const { status, title, url, height, width, hasFocalPoint, focalPoint } =
      image;
    return {
      eagerUrl: buildUrl(url, defaultParams(true, height / width, 1)),
      status,
      title,
      url,
      height,
      width,
      focalPoint: hasFocalPoint ? focalPoint : false,
    };
  }
  return null;
};

export default resolveImage;
