module.exports = {
  colors: {
    white: { DEFAULT: "#ffffff" },
    cream: { DEFAULT: "#F9F6EF" },
    black: { alpha: "#0F0703EB", alphalight: "#0F0703BF", DEFAULT: "#0F0703" },
    gray: {
      DEFAULT: "rgba(51, 43, 41, 0.5)",
      light: "#EDF0F2",
      "light-alpha": "#EDF0F24D",
    },
    brown: { DEFAULT: "#332B29" },
    red: { light: "#DF4D48", DEFAULT: "#BA2B26", dark: "#982424" },
  },
  lineHeight: {
    less: ".9",
    tighter: 1.125,
  },
  fontFamily: {
    sans: ["franklin-gothic-urw", "sans-serif"],
    serif: ["arno-pro", "serif"],
  },
};
