import React from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import { m } from "framer-motion";
import Icon from "./Icon";

const DonationBanner = ({ name, amount, fundraiser, delay, style }) => {
  return (
    <m.li
      animate={{ y: "100%", opacity: 0, transition: { delay } }}
      className={clsx(
        "text-uppercase font-serif absolute inset-0 z-10 flex items-center bg-black p-6 text-sm font-semibold text-white"
      )}
      style={style}
    >
      <Icon name="donation" className={clsx("mr-4 h-8 w-8", {})} />
      <span>
        {`${name} donated $${amount.toLocaleString()}`}{" "}
        {fundraiser && (
          <span>
            to{" "}
            <AppLink
              className={clsx("underline-2", {})}
              to={`/${fundraiser.slug}`}
            >
              {fundraiser.title}
            </AppLink>
          </span>
        )}
      </span>
    </m.li>
  );
};

DonationBanner.defaultProps = {};

export default DonationBanner;
