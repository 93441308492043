import React, { useEffect } from "react";
import { LazyMotion } from "framer-motion";
import { useAppState } from "@state";
import "../../pcss/fonts/fontslgca.pcss";

import Themer from "../atoms/Themer";
import LgcaHeader from "@organisms/LgcaHeader";
import LgcaEcFooter from "@organisms/LgcaEcFooter";
import Modal from "@organisms/Modal";
const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, pageContext: context }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [{ layout }] = useAppState();
  const { theme } = layout;
  const { isEc } = context;

  useEffect(() => {
    if (isEc) {
      try {
        // eslint-disable-next-line global-require
        const o9n = require("o9n");
        o9n.orientation.lock("portrait").then(
          // eslint-disable-next-line no-unused-vars
          success => {
            // console.log(success);
          },
          failure => {
            // eslint-disable-next-line no-console
            console.log(failure);
          }
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, [isEc]);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={`transitions lgca relative flex w-screen flex-col duration-500 ${
          theme === "light" ? "bg-white" : "bg-orange"
        }`}
      >
        <LgcaHeader isEc={isEc} />
        {isEc && <LgcaEcFooter />}
        {children}

        <div id="fb-root" />
        <Modal />
        <Themer />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
